import React, { useState, useEffect, useRef } from "react";
import styles from "./ImageView.module.css";
import TakeImageIcon from "@material-ui/icons/CameraAlt";

const ACCEPT_UPLOAD_FILE_TYPES = ["image/jpeg", "image/jpg", "image/png"];

export default function ImageView({ handleImageChange: onImageChange }) {
  // states
  const [imageSrc, setImageSrc] = useState("");

  // refs
  const dropAreaRef = useRef(null);
  //   const uploadBtnRef = useRef(null);
  const uploadInputRef = useRef(null);

  const onInputFileChange = (event) => {
    //getting user select file and [0] this means if user select multiple files then we'll select only the first one
    const { current: dropArea } = dropAreaRef;
    const { current: uploadInput } = uploadInputRef;
    if (dropArea && uploadInput) {
      const file = uploadInput.files[0];
      showFile(file); //calling function
    }
  };

  const onDragOver = (event) => {
    event.preventDefault(); //preventing from default behaviour
    const { current: dropArea } = dropAreaRef;
    if (dropArea) {
      dropArea.classList.add(styles["active"]);
    }
  };

  const onDragLeave = (event) => {
    const { current: dropArea } = dropAreaRef;

    dropArea.classList.remove(styles["active"]);
  };

  const onDrop = (event) => {
    event.preventDefault(); //preventing from default behaviour
    const { current: dropArea } = dropAreaRef;

    //getting user select file and [0] this means if user select multiple files then we'll select only the first one
    if (dropArea) {
      dropArea.classList.remove(styles["active"]);
    }
    const file = event.dataTransfer.files[0];
    showFile(file);
  };

  function showFile(file) {
    let fileType = file.type; //getting selected file type
    if (ACCEPT_UPLOAD_FILE_TYPES.includes(fileType)) {
      //if user selected file is an image file
      let fileReader = new FileReader(); //creating new FileReader object
      fileReader.onload = () => {
        let fileURL = fileReader.result; //passing user file source in fileURL variable
        setImageSrc(fileURL);
      };
      fileReader.readAsDataURL(file);
      onImageChange(file);
    } else {
      alert("This is not an Image File!");
      if (dropAreaRef.current) {
        dropAreaRef.current.classList.remove(styles["active"]);
      }
    }
  }

  if (imageSrc === "") {
    return (
      <div
        ref={dropAreaRef}
        className={styles["drag-area"]}
        onDragOver={onDragOver}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
      >
        <TakeImageIcon
          style={{ fontSize: 100 }}
          onClick={() => {
            if (uploadInputRef.current) uploadInputRef.current.click();
          }}
        />
        <button
          onClick={() => {
            if (uploadInputRef.current) uploadInputRef.current.click();
          }}
        >
          Browse File
        </button>
        <input
          ref={uploadInputRef}
          onChange={onInputFileChange}
          type="file"
          hidden
          accept={ACCEPT_UPLOAD_FILE_TYPES.join(", ")}
        />
      </div>
    );
  } else {
    return (
      <div className={styles["drag-area-image"]}>
        <div>
          <img src={imageSrc} alt="uploaded data" />
        </div>
        <button
          style={{ justifySelf: "flex-end" }}
          onClick={() => {
            setImageSrc("");
            onImageChange(null);
          }}
        >
          Reset
        </button>
      </div>
    );
  }
}
