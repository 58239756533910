import { AuthContextProvider } from "./components/AuthContext";
import IRISThemeProvider from "./utils/theme/theme";
import AppRoutes from "./routes/AppRoutes";
import AlertContextProvider from "./irisUI/alert/context/AlertContextProvider";
import AlertModal from "./irisUI/alert/view/AlertModal";
import ConfirmContextProvider from "./irisUI/confirm/context/ConfirmContextProvider";
import ConfirmModal from "./irisUI/confirm/view/ConfirmModal";

function App() {
  return (
    <IRISThemeProvider>
      {/* global alert context and ui */}
      <AlertContextProvider>
        <AlertModal />
        <ConfirmContextProvider>
          <ConfirmModal />
          <AuthContextProvider>
            <AppRoutes />
          </AuthContextProvider>
        </ConfirmContextProvider>
      </AlertContextProvider>
    </IRISThemeProvider>
  );
}

export default App;
