import { loadModules } from "esri-loader";
import moment from "moment-timezone";
import { useEffect, useRef, useState } from "react";
import { getLast30DaysWorkOrders } from "utils/requests";

const ALL_KEY = "ALL";

export const LAYER_ID = "WORK_ORDER";

const KEYS = {
  lon: {
    label: "Longitude",
  },
  lat: {
    label: "Latitude",
  },
  w_o_type: {
    label: "Type",
  },
  w_o_status: {
    label: "Status",
  },
  assign_to: {
    label: "Assigned To",
  },
  image_url: {
    label: "Image",
  },
  open_datetime: {
    label: "Opened on",
  },
};

const getRenderer = (size, width, color) => {
  return {
    type: "simple", // autocasts as new SimpleRenderer()
    symbol: {
      type: "simple-marker", // autocasts as new SimpleMarkerSymbol()
      size: size,
      color: color,
      outline: {
        // autocasts as new SimpleLineSymbol()
        width: width,
        color: "white",
      },
    },
  };
};

const getFeatures = (title, data, renderer) => {
  const fs = data.map((d) => ({
    geometry: {
      type: "point",
      longitude: d.lon,
      latitude: d.lat,
    },

    attributes: Object.keys(d).reduce((acc, key) => {
      if (key === "open_datetime") {
        acc[key] = moment.tz(d[key], "America/Toronto").toString();
      } else {
        acc[key] = d[key] + "";
      }
      return acc;
    }, {}),
  }));
  const a = {
    title: title,
    source: fs,
    objectIdField: "id",

    fields: Object.keys(KEYS).map((key) => ({
      name: key,
      type: "string",
    })),

    renderer: renderer,
    popupTemplate: {
      // https://developers.arcgis.com/javascript/latest/api-reference/esri-PopupTemplate.html#title
      title: "Address: {address}",
      outFields: ["*"],
      content: [
        {
          type: "media",
          mediaInfos: [
            {
              type: "image", // Autocasts as new ImageMediaInfo object
              value: {
                sourceURL: "{image_url}",
              },
            },
          ],
        },
        {
          type: "fields",
          fieldInfos: Object.keys(KEYS).map((key) => ({
            fieldName: key,
            label: KEYS[key].label,
          })),
        },
      ],
    },
  };

  return a;
};
function useWorkOrderFeatureLayer(
  showWorkorder,
  layerId,
  cityName,
  timezone,
  token
) {
  const [layer, setLayer] = useState(null);
  const [workorderTypes, setWorkorderTypes] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(ALL_KEY);

  const workorderDataObjRef = useRef();

  useEffect(() => {
    if (showWorkorder !== true) return;
    loadModules([
      "esri/layers/FeatureLayer",
      "esri/Graphic",
      "esri/widgets/Legend",
      "esri/layers/GroupLayer",
    ])
      .then(async ([FeatureLayer, Graphic, Legend, GroupLayer]) => {
        const workorderDataObj = workorderDataObjRef.current;
        let workorders = [];

        if (!workorderDataObj) {
          const data = await getLast30DaysWorkOrders(
            cityName,
            timezone,
            token
          ).catch((e) => {
            console.error(e);
          });

          const groupedData = data.reduce((acc, ele) => {
            const type = ele.w_o_type;
            if (!acc[type]) {
              acc[type] = [ele];
            } else {
              acc[type].push(ele);
            }
            return acc;
          }, {});
          groupedData[ALL_KEY] = data;
          const keys = Object.keys(groupedData).sort();
          const workorderTypes = keys.map((key) => ({
            name: key,
            id: key,
          }));
          setWorkorderTypes(workorderTypes);
          workorderDataObjRef.current = groupedData;
          workorders = data;
        } else {
          workorders = workorderDataObj[selectedCategory];
        }

        const openedData = [];
        const closedData = [];

        workorders.forEach((d) => {
          if (d.w_o_status === "Opened") {
            openedData.push(d);
          } else if (d.w_o_status === "Closed") {
            closedData.push(d);
          }
        });

        let openedFeatures,
          closedFeatures,
          layers = [];
        if (closedData.length > 0) {
          closedFeatures = new FeatureLayer(
            getFeatures(
              `Closed (${closedData.length})`,
              closedData,
              getRenderer(10, 0.5, "green")
            )
          );
          layers.push(closedFeatures);
        }

        if (openedData.length > 0) {
          openedFeatures = new FeatureLayer(
            getFeatures(
              `Opened (${openedData.length})`,
              openedData,
              getRenderer(10, 0.5, "red")
            )
          );
          layers.push(openedFeatures);
        }

        const demographicGroupLayer = new GroupLayer({
          title: `Work Orders - ${selectedCategory} (${workorders.length})`,
          id: LAYER_ID,
          // visibilityMode: "exclusive",
          visible: true,
          layers: layers,
          opacity: 0.75,
        });

        setLayer(demographicGroupLayer);
      })
      .catch((error) => {
        alert("Error in loading arcgis modules");
        console.error("Error in loading arcgis modules", error);
      });
  }, [showWorkorder, layerId, cityName, timezone, token, selectedCategory]);

  return {
    LAYER_ID, // use to target the current layer, to remove / to
    layer,
    workorderTypes,
    selectedCategory,
    setSelectedCategory,
  };
}

export default useWorkOrderFeatureLayer;
