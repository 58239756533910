import { useContext, useEffect, useState, useRef, useCallback } from "react";
import { AuthContext } from "components/AuthContext";
import { getDeviceStatus } from "utils/api/patrolAPI";
import audioFile from "../../assets/audio/device_offline_warning_sound_edited.mp3";
import { IRIS_GREEN, IRIS_RED } from "utils/theme/colors";
import styles from "./DeviceIndicator.module.css";

const DEVICE_STATUS = {
  /** loading because of calling API */
  LOADING: "Loading",
  /** API returns good response and device is marked as "active": true */
  ONLINE: "Online",
  /** API returns good response and device is marked as "active": false */
  OFFLINE: "Offline",
  /** before calling API or API did not return "ok" response */
  "N/A": "N / A",
};
function DeviceIndicator() {
  const { deviceInfo } = useContext(AuthContext);

  /** audo object that plays warning sound */
  const audioRef = useRef(new Audio(audioFile));

  /** determiend whehter device was marked as online */

  const [deviceStatus, setDeviceStatus] = useState(DEVICE_STATUS["N/A"]);

  const playWarningSound = useCallback(() => {
    // const { current: audio } = audioRef;
    // if (audio) {
    //   audio.loop = true;
    //   audio.load();
    //   audio.play();
    // }
  }, []);

  const pauseWarningSound = useCallback(() => {
    const { current: audio } = audioRef;
    if (audio) {
      audio.pause();
    }
  }, []);

  /**
   * @summary get device status every minute
   *
   * @description onMounted, fetch device status, then fetch the same info in every minutes.
   *
   * When it is awaiting response, the device status is marked as "Loading".
   *
   * When response is returned, check the value of "active"
   *
   * if it is true, mark device as "Online", otherwise, mark as "Offline"
   *
   * When error is returned, mark device status as N/A.
   *
   */
  useEffect(() => {
    if (!deviceInfo) return;
    const MS = 1 * 60 * 1000;
    const fetchDeviceStatus = () => {
      setDeviceStatus(DEVICE_STATUS.LOADING);
      getDeviceStatus(deviceInfo.id)
        .then((deviceStatus) => {
          if (deviceStatus?.active) {
            setDeviceStatus(DEVICE_STATUS.ONLINE);
          } else {
            setDeviceStatus(DEVICE_STATUS.OFFLINE);
          }
        })
        .catch((e) => {
          setDeviceStatus(DEVICE_STATUS["N/A"]);
        });
    };

    fetchDeviceStatus();
    const interval = setInterval(() => {
      fetchDeviceStatus();
    }, MS);
    return () => {
      if (interval) {
        clearInterval(interval);
      }

      pauseWarningSound();
    };
  }, [deviceInfo, playWarningSound, pauseWarningSound]);

  if (!deviceInfo) {
    return <span></span>;
  }
  return (
    <div className={styles.container}>
      <span className={styles.tagSpan}>{deviceInfo.tag}</span>
      <span
        className={styles.statusSpan}
        style={{
          backgroundColor:
            deviceStatus === DEVICE_STATUS.OFFLINE ? IRIS_RED : IRIS_GREEN,
        }}
      >
        {deviceStatus}
      </span>
    </div>
  );
}

export default DeviceIndicator;
