import React, { useEffect } from "react";
import { Theme, makeStyles } from "@material-ui/core/styles";

import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const MENU_MAX_HEIGHTS = [100, 200, 300, 400, 500, 600] as const;
type MenuMaxHeightType = (typeof MENU_MAX_HEIGHTS)[number];

type Props = {
  maxHeight?: MenuMaxHeightType;
};
const useStyles = makeStyles<Theme, Props>((theme) => ({
  button: {
    display: "block",
    marginTop: theme.spacing(2),
  },

  menuPaper: {
    maxHeight: (props) => props.maxHeight || "calc(100% - 96px)", // default value
  },
}));

interface MUISelectProps {
  values: { [key: string]: any }[];
  labelField: string;
  valueField: string;
  label: string;
  onSelect: (value: { [key: string]: any }) => void;
  value: string;
  fullWidth: boolean;
  disabled: boolean;
  renderValue: (ele: any, index: number) => any;
  menuMaxHeight?: MenuMaxHeightType;
}
export default function MuiSelect(props: MUISelectProps) {
  const {
    values,
    labelField,
    valueField,
    label = "",
    onSelect: handleSelect,
    value: defaultValue = "",
    fullWidth = false,
    disabled = false,
    renderValue = null,
    menuMaxHeight,
  } = props;

  const classes = useStyles({ maxHeight: menuMaxHeight });
  const [value, setValue] = React.useState(defaultValue);
  const [open, setOpen] = React.useState(false);

  const handleChange = (
    event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>,
    child: React.ReactNode
  ) => {
    const selectedValue = event.target.value;
    if (typeof selectedValue === "string") {
      let foundObj = values.find(
        (value) => value[valueField] === selectedValue
      );

      if (typeof handleSelect === "function") {
        if (!foundObj) {
          foundObj = { [labelField]: "", [valueField]: "" };
        }
        handleSelect(foundObj);
      }

      setValue(selectedValue);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (typeof defaultValue === "string") {
      setValue(defaultValue);
    } else if (typeof defaultValue === "object" && defaultValue[valueField]) {
      setValue(defaultValue[valueField]);
    }
  }, [defaultValue, valueField]);

  return (
    <div>
      <FormControl fullWidth={fullWidth}>
        <InputLabel>{label}</InputLabel>
        <Select
          disabled={disabled}
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          value={value}
          onChange={handleChange}
          MenuProps={{
            getContentAnchorEl: null,
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            classes: {
              paper: classes.menuPaper,
            },
          }}
        >
          {/* show none selection only when value === "", ie: on mounted */}
          {value === "" && (
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
          )}
          {values.map((ele, index) => {
            const value = ele[valueField];
            const label = ele[labelField];

            if (renderValue) {
              return renderValue(ele, index);
            } else {
              return (
                <MenuItem key={index} value={value}>
                  {label}
                </MenuItem>
              );
            }
          })}
        </Select>
      </FormControl>
    </div>
  );
}
