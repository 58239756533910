// Open Weather API
const OPEN_WEATHER_URL = new URL("https://api.openweathermap.org/data/2.5/");
const OPEN_WEATHER_CURRENT_URL = `${OPEN_WEATHER_URL}weather`;

// city id (code)
// http://web.archive.org/web/20180619015316/http://openweathermap.org/help/city_list.txt
export const OPEN_WEATHER_API_KEY = "7af04e4481a439647aa3e71222e46ee9";

/**
 *
 * @see {@link https://openweathermap.org/current API doc} for further information.
 * @param {number} cityId
 * @param {"C" | "F"} degreePreference
 * @returns
 */
export const getCurrentWeather = (cityId, degreePreference) => {
  return new Promise((resolve, reject) => {
    const url = new URL(OPEN_WEATHER_CURRENT_URL);
    const unit = degreePreference === "F" ? "imperial" : "metric";
    url.searchParams.append("id", cityId);
    url.searchParams.append("appid", OPEN_WEATHER_API_KEY);
    url.searchParams.append("units", unit);
    fetch(url, {
      method: "GET",
      redirect: "follow",
    })
      .then((response) => {
        if (response.status === 200) {
          response.json().then((data) => {
            resolve({
              dt: data.dt,
              temp: data.main.temp,
              city: data.name,
              condition: data.weather[0].main,
              icon: data.weather[0].icon,
            });
          });
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};
