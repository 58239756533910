import { Button } from "@material-ui/core";
import { useEffect, useState, useContext } from "react";

// import ExpandLessIcon from "@material-ui/icons/ExpandLess";
// import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";

import TabPanelContent from "./tabPanel/TabPanelContent";

import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";

import "./style.css";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ExpandLess from "@material-ui/icons/ExpandLess";

// import moment from "moment";

import { MDSSContext } from "context/MDSSContext";

import useConfirm from "../../../irisUI/confirm/hoc/useConfirm";
import useAuth from "auth/hook/useAuth";

const date = new Date();
// const WeekDay = ["Sun", "Mon", "Tue", "Wed", "Thur", "Fri", "Sat"];
const Month = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
const year = date.getFullYear();
const month = Month[date.getMonth()];
const day = date.getDate();
// const weekDay = WeekDay[date.getDay()];
// const hour = date.getHours();

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

//for tabs
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `wrapped-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  head: {
    backgroundColor: "var(--mdss-blue)",
    fontSize: "2.3rem",
    borderRadius: "0.5rem 0.5rem 0 0",
    height: "4.2rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#fff",
  },
  tabsContainer: {
    backgroundColor: "var(--mdss-blue)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  tabs: {
    alignItems: "center",
    height: "4.5rem",
  },
  tab: {
    border: "var(--mdss-white) solid 0.1rem",
    color: "var(--mdss-white)",
    marginRight: theme.spacing(1.5),
    marginLeft: theme.spacing(1.5),
    minWidth: 0,
    minHeight: 0,
    padding: "0.2rem 0.5rem",
  },
  TPanel: {
    padding: 0,
  },
  theDate: {
    textAlign: "center",
    color: "var(--mdss-white)",
    fontSize: "2rem",
    fontWeight: 700,
    margin: 0,
  },
}));

export default function WeatherForcecastView({ page }) {
  //mdss context
  const { showRoadCondition, handleShowRoadCondition, MDSSData, error } =
    useContext(MDSSContext);

  const {
    pageConfig: {
      patrolConfig: { additionalSetting: { mdss = true } = {} } = {},
    },
  } = useAuth();

  /** group the data with route id, which are king vaughan and martin grove */
  const tabsBy = "route_id";

  /** function to return the grouped data by tab */
  const tabGrouping = (inputArray) => {
    const returnObj = {};
    inputArray.forEach((element) => {
      if (returnObj[`${element[tabsBy]}`]) {
        returnObj[`${element[tabsBy]}`].push(element);
      } else {
        returnObj[`${element[tabsBy]}`] = [];
        returnObj[`${element[tabsBy]}`].push(element);
      }
    });
    return returnObj;
  };

  /** data grouped by tab, which is route_id */
  const groupedData = tabGrouping(MDSSData);

  /** render data on the page */
  const renderDataKeys = Object.keys(groupedData);

  /** required render properties */
  const renderProperties = [
    "time",
    "road_alert",
    "Road Temperature (°C)", //"road_temperature",
    "treatment",
    "plow",
    "Air Temperature (°C)", //"air_temperature",
    "Snow on Road (cm)", //"snow_on_road",
    "weather_alert",
    "precipitation", //nested data, including both precipitation type and rate
  ];

  //pick the required properties above
  renderDataKeys.forEach((oneRenderData) => {
    groupedData[`${oneRenderData}`] = groupedData[`${oneRenderData}`].map(
      (oneData) =>
        Object.keys(oneData)
          .filter((key) => renderProperties.includes(key))
          .reduce((obj, key) => {
            obj[key] = oneData[key];
            return obj;
          }, {})
    );
  });

  /** tab labels(hard code), following the order as: king vaughan then martin grove */
  const tabLabels = { DONVKV: "King Vaughan", DONVMG: "Martin Grove" };

  //customized confirm message
  const { isConfirmed } = useConfirm();
  //hide the large component when clicking on the backdrop
  const hideWeather = async (e) => {
    if (e.target.className === "back-drop") {
      // handleShowRoadCondition(false);
      if (page === "form") {
        const confirmed = await isConfirmed(
          "Confirming that I have viewed the road condition and weather forecast."
        );
        if (confirmed) {
          handleShowRoadCondition(false);
          setTimeExpanded(false);
        }
      } else if (page === "map") {
        handleShowRoadCondition(false);
        setTimeExpanded(false);
      }
    }
  };

  //the whole mdss component
  const [isExpanded, setIsExpanded] = useState(true);
  const classes = useStyles();

  //for the tab
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setTimeExpanded(false);
    // setConditionColor("default");
  };

  //for tab panel content prop, indicating if the accordion content shows
  const [timeExpanded, setTimeExpanded] = useState(false);

  const handleChangeTime = (panel, condition) => (e, isOpen) => {
    setTimeExpanded(isOpen ? panel : false);
    // setConditionColor(isOpen ? condition : "default");
  };

  return (
    <div>
      {showRoadCondition && mdss && (
        <div className="back-drop" onClick={hideWeather}>
          <div
            className={`expandable ${isExpanded ? "expandable-expanded" : ""}`}
          >
            <div
              className="header"
              fullWidth
              endIcon={isExpanded ? <ExpandMore /> : <ExpandLess />}
            >
              <div
                className={classes.head}
                // onClick={() => setIsExpanded((prev) => !prev)}
              >
                <ErrorOutlineIcon
                  style={{ fontSize: "2.2rem", marginRight: "10" }}
                ></ErrorOutlineIcon>
                ROAD CONDITION
                <ErrorOutlineIcon
                  style={{ fontSize: "2.2rem", marginLeft: "10" }}
                ></ErrorOutlineIcon>
              </div>
              <div position="static" className={classes.tabsContainer}>
                <Tabs
                  className={classes.tabs}
                  value={value}
                  onChange={handleChange}
                >
                  {Object.values(tabLabels).map((oneTabLabel, index) => (
                    <Tab
                      className={classes.tab}
                      label={oneTabLabel}
                      {...a11yProps(index)}
                      style={{ fontSize: "1.5rem" }}
                      key={index}
                    />
                  ))}
                </Tabs>
              </div>
            </div>
            <div className="content">
              {groupedData &&
                Object.keys(tabLabels).map((oneLabel, index) => {
                  const oneRenderData = groupedData[oneLabel];
                  return (
                    <TabPanel
                      value={value}
                      index={index}
                      className={classes.TPanel}
                      key={index}
                    >
                      <p className={classes.theDate}>
                        {month} {day} {year}
                      </p>
                      {error && <p className="fetch-error">{error}</p>}
                      {oneRenderData &&
                        oneRenderData.map((oneData, index) => (
                          <TabPanelContent
                            key={index}
                            timeExpanded={timeExpanded}
                            handleChangeTime={handleChangeTime}
                            weatherTime={oneData.time}
                            ACCORDION_DETAILS={oneData}
                          />
                        ))}
                    </TabPanel>
                  );
                })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
