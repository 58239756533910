export default class DateUtils {
  /**
   * @summary get date object from given param
   *
   * @param {Date | string | null | undefined} param
   *
   * @returns data object generated by the param, default return current date object
   */
  static getDateObject(param) {
    const temp = new Date(param);
    if (isNaN(temp.getTime())) {
      return new Date();
    }
    return temp;
  }

  static append0(num) {
    const numString = "" + num;
    if (numString.length < 2) {
      return "0" + numString;
    }
    return numString;
  }

  /**
   * @summary determined whether a date is a valid date
   *
   * @param {Date | string} date
   * @returns true if given string or date is a valid date, otherwise, false
   */
  static isValid(date) {
    const dateObject = new Date(date);
    return !isNaN(dateObject.getTime());
  }

  /**
   * @summary get local date relative to the given date
   *
   * @param {Date} date
   * @returns local date relative to the givne date
   */
  static getLocalDate(date = new Date()) {
    const temp = new Date(date);
    const hoursDiff = temp.getTimezoneOffset() / 60;
    temp.setHours(temp.getHours() + hoursDiff);
    return temp;
  }

  /**
   * @summary get the yyyy-mm-dd string format, relative to local date time, of the given date object
   *
   * @param {Date | string | null | undefined} date
   * @returns string in yyyy-mm-dd formate of the given date, relative to local date time
   */
  static getyyyyMMdd(date) {
    let dateObject;
    if (!date) {
      dateObject = new Date();
    } else if (this.isValid(date)) {
      dateObject = new Date(date);
    }

    dateObject = this.getLocalDate(dateObject);
    const yyyy = dateObject.getFullYear();
    const mm = dateObject.getMonth() + 1;
    const dd = dateObject.getDate();
    const mmString = this.append0(mm);
    const ddString = this.append0(dd);
    return `${yyyy}-${mmString}-${ddString}`;
  }
  static getTimeFormat24(date = new Date()) {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    const hourString = this.append0(hours);
    const munteString = this.append0(minutes);
    const secondString = this.append0(seconds);
    return `${hourString}:${munteString}:${secondString}`;
  }
  static getTimeFormat12(date = new Date()) {
    let ampm = "am";
    let hours = date.getHours();

    if (hours === 12) {
      ampm = "pm";
    } else if (hours > 12) {
      ampm = "pm";
      hours %= 12;
    }
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    const hourString = this.append0(hours);
    const munteString = this.append0(minutes);
    const secondString = this.append0(seconds);
    return `${hourString}:${munteString}:${secondString} ${ampm}`;
  }

  static getyyyyMMddHHmmss(date = new Date()) {
    const datePart = this.getyyyyMMdd(date);
    const timePart = this.getTimeFormat24(date);
    return `${datePart} ${timePart}`;
  }
  static getyyyyMMddhhmmss(date = new Date()) {
    const datePart = this.getyyyyMMdd(date);
    const timePart = this.getTimeFormat12(date);
    return `${datePart} ${timePart}`;
  }

  /**
   * @summary get timestamp string in formart yyyy-MM-ddTHH:mm:ss-04:00
   * @param {Date | string | null | undefined} param
   * @returns timestamp for data base yyyy-MM-ddTHH:mm:ss-04:00
   */
  static getDatabaseTimeStamp(param) {
    const date = this.getDateObject(param);

    const localDateString = date.toString();
    // "Wed Oct 20 2021 11:22:30 GMT-0400 (Eastern Daylight Time)"

    const localRelativeToUTCString = localDateString.split("GMT")[0] + "UTC";
    // "Wed Oct 20 2021 11:22:58 UTC"

    const localRelativeToUTCDateObject = new Date(localRelativeToUTCString);
    // 2021-10-20T11:23:38.000Z

    const localISOString = localRelativeToUTCDateObject.toISOString();
    // "2021-10-20T11:24:19.000Z"

    const GMTPart = localDateString.split("GMT")[1];
    // "-0400 (Eastern Daylight Time)"

    const timezoneString = GMTPart.split(" ")[0];
    // "-0400"

    let tzs = timezoneString.substr(0, 3) + ":" + timezoneString.substr(3);
    // "-04:00"

    const temp = localISOString.replace("Z", tzs);
    // "2021-10-20T11:24:19.000-04:00"

    return temp;
  }
}
