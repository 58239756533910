import moment from "moment";
import DateUtils from "utils/dateUtils/DateUtils";
import i18n from "config/i18n";

export const DATE_TIME_FORMATS = {
  fr: {
    /** mar. 30 août 2022 11:47 */
    FULL: "ddd D MMMM YYYY, HH:mm",
    /**  mar. 30 août 2022 */
    D_DATE: "ddd D MMMM YYYY",
    /** 30 août 2022 */
    DATE: "D MMMM YYYY",
  },
  /** Tue Aug 30 2022 11:47 AM*/
  EN: "ddd. MMM D, YYYY, hh:mm A",

  en: {
    /** Tue Aug 30 2022 11:47 AM*/
    FULL: "ddd. MMM D, YYYY, hh:mm A",
    /**  Tue Aug 30 2022 */
    D_DATE: "ddd. MMM D, YYYY",
    /** Aug 30 2022 */
    DATE: "MMM D, YYYY",
  },
  ja: {
    // 2023年4月27日 (木) 15:11
    FULL: "YYYY年M月D日 (ddd) HH:mm",
    // 2023年4月27日 (木) 15:11
    D_DATE: "YYYY年M月D日 (ddd) HH:mm",
    // 2023年4月27日 (木) 15:11
    DATE: "YYYY年M月D日 (ddd) HH:mm",
  },
};

/**
 *
 * @param {String | number | Date} dateTimeValue
 * @param {"FULL" | "D_DATE" | "DATE"} [type]
 * * `FULL` -> `Tue Aug 30 2022 11:47 AM`
 * * `D_DATE` -> `Tue Aug 30 2022`
 * * `DATE` -> `Aug 30 2022`
 * @returns
 */
export const getTranslatedTime = (dateTimeValue, type = "FULL") => {
  if (dateTimeValue === "") {
    // allow empty time string
    return "";
  } else if (!DateUtils.isValid(dateTimeValue)) {
    return `invalid time - ${dateTimeValue}`;
  }
  const { language } = i18n;
  if (!language) {
    return dateTimeValue;
  }
  moment.locale(language);
  const f = DATE_TIME_FORMATS[language][type];
  const localTimeString = moment(dateTimeValue).format(f);
  return localTimeString;
};
