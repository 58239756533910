import { IRIS_DEVICE_CLIENT_URL, IRIS_VERITY_USER_URL } from "utils/env/urls";

/**
 *
 * @param {string} token
 * @returns {Promise<TypeIrisUser>}
 */
export const getIrisUser = async (token) => {
  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  return new Promise(async (resolve, reject) => {
    // fetch API error, ie: Failed to fetch due to CORS.
    let fetchError;
    const response = await fetch(IRIS_VERITY_USER_URL, options).catch((e) => {
      fetchError = e;
    });

    if (fetchError) {
      reject(fetchError);
    } else {
      if (response.ok) {
        const responseJson = await response.json();
        responseJson.token = token;
        resolve(responseJson);
      } else {
        let error;
        const { status } = response;
        const message = await response.text();
        const errorMessage = `Error ${status}: ${message}`;
        if (status === 401 || status === 403) {
          error = new Error(errorMessage);
        } else if (status === 404) {
          error = new Error(errorMessage);
        } else if (status >= 500) {
          error = new Error(errorMessage);
        } else {
          error = new Error(errorMessage);
        }
        reject(error);
      }
    }
  });
};
/**
 *
 * @param {string} token
 * @returns {Promise<TypeIrisUser>}
 */
export const getDeviceByClientID = async (token, id) => {
  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  return new Promise(async (resolve, reject) => {
    // fetch API error, ie: Failed to fetch due to CORS.

    let fetchError;
    const response = await fetch(
      IRIS_DEVICE_CLIENT_URL +
        "?" +
        new URLSearchParams({
          client_id: id,
          detailed: true,
        }),
      options
    ).catch((e) => {
      fetchError = e;
    });

    if (fetchError) {
      reject(fetchError);
    } else {
      if (response.ok) {
        const responseJson = await response.json();
        resolve(responseJson);
      } else {
        let error;
        const { status } = response;
        const message = await response.text();
        const errorMessage = `Error ${status}: ${message}`;
        if (status === 401 || status === 403) {
          error = new Error(errorMessage);
        } else if (status === 404) {
          error = new Error(errorMessage);
        } else if (status >= 500) {
          error = new Error(errorMessage);
        } else {
          error = new Error(errorMessage);
        }
        reject(error);
      }
    }
  });
};
