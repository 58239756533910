import DateUtils from "./datetimeUtils/DateUtils";
import {
  IRISGO_BASE_URL,
  IRISGO_LOGIN_URL,
  IRISGO_LOOKUP_URL,
  IRISGO_FETCH_URL,
  IRISGO_DEVICE_UPDATE_URL,
  IRISGO_USER_PROFILE_UPDATE_URL,
  IRISGO_DEVICE_USAGE_URL,
  SENTRY_GATEWAY_LOOKUP_URL,
  IRISGO_NEW_DEVICE_URL,
  OPEN_WEATHER_CURRENT_URL,
  IRISGO_CREATE_NEW_USER_URL,
  IRISGO_RESET_USER_PASSWORD,
  REQUEST_NEW_ACCOUNT_URL,
  IRISGO_DOWNLOAD_CSV_URL,
  IRISGO_LABEL_URL,
  IRIS_WORK_ORDER_END_POINT,
} from "./env/urls";

export const LOGIN_REQUEST = (username, password) => {
  return new Promise((resolve, reject) => {
    fetch(IRISGO_LOGIN_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ username: username, password: password }),
    })
      .then((result) => {
        if (result.status === 200) {
          result.json().then((data) => {
            // window.gtag("event", `${data.city.name}_${username}_Login`);
            resolve({
              token: data.access,
              id: data.user_id,
              group: data.groups,
              preference: data.preference,
              city: data.city,
              disabled_widgets: data.disabled_widgets,
              reset: data.reset,
              first_name: data.first_name,
              last_name: data.last_name,
            });
          });
        } else if (result.status === 401) {
          reject("Your account or password is incorrect.");
        } else {
          reject("Server Error");
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// const USERGROUP_REQUEST = (username, token) => {
//   return new Promise((resolve, reject) => {
//     const url = new URL(IRISGO_LOOKUP_URL);
//     url.searchParams.append("user", true);
//     fetch(url, {
//       method: "GET",
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${token}`,
//       },
//     })
//       .then((response) => {
//         if (response.status === 401) {
//           reject(new Error("ACCESS DENIED"));
//         } else {
//           response.json().then((data) => {
//             if (data.success === true) {
//               var users = data.result.user;
//               const foundUser = users.find(
//                 (user) => user.username === username
//               );
//               resolve(foundUser.groups);
//             } else {
//               reject(new Error("Error in getting user group data"));
//             }
//           });
//         }
//       })
//       .catch((error) => {
//         reject(error);
//       });
//   });
// };

export const MOBILE_DEVICE_IPORT_UPDATE_REQUEST = (id, data, token) => {
  return new Promise((resolve, reject) => {
    fetch(`${IRISGO_DEVICE_UPDATE_URL}${id}/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    })
      .then((result) => {
        if (result.status === 200) {
          resolve("iPort Device Update OK");
        } else {
          reject("iPort Device Update Failed");
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const MOBILE_DEVICE_NEW_DEVICE_REQUEST = (newDevice, token) => {
  return new Promise((resolve, reject) => {
    fetch(IRISGO_NEW_DEVICE_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(newDevice),
    })
      .then((result) => {
        if (result.status === 200) {
          result.json().then((data) => {
            resolve(data.result.activate);
          });
        } else {
          reject("iPort New Device Creation Failed");
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const MOBILE_REPORT_LOOKUP_REQUEST = (token) => {
  return new Promise((resolve, reject) => {
    const url = new URL(IRISGO_LOOKUP_URL);
    url.searchParams.append("city", true);
    url.searchParams.append("damage_type", true);
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (response.status === 401) {
          reject(new Error("ACCESS DENIED"));
        } else {
          response.json().then((data) => {
            if (data.success === true) {
              // temp filter
              data.result.damage_type = data.result.damage_type.filter(
                (damage) => damage.enabled !== "N"
              );
              resolve(data.result);
            } else {
              reject(new Error("Error in city and damage_type lookup data"));
            }
            resolve(data);
          });
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const MOBILE_REPORT_DATAPOINT_REQUEST = (date, city, token) => {
  return new Promise((resolve, reject) => {
    const url = new URL(IRISGO_FETCH_URL);
    url.searchParams.append("date", date);
    if (city !== 0) url.searchParams.append("city", city);
    url.searchParams.append("with_defect", true);
    url.searchParams.append("page_size", 100000);

    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (response.status === 401) {
          reject(new Error("ACCESS DENIED"));
        } else {
          response.json().then((data) => {
            resolve(data);
          });
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const STATIC_DEVICE_LIST_REQUEST = () => {
  return new Promise((resolve, reject) => {
    const url = new URL(SENTRY_GATEWAY_LOOKUP_URL);
    url.searchParams.append("device", true);
    fetch(url, {
      method: "GET",
      redirect: "follow",
    })
      .then((response) => {
        if (response.status === 200) {
          response.json().then((data) => {
            resolve(data.device);
          });
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const MOBILE_DEVICE_USAGE_REQUEST = (month, year, city, token) => {
  return new Promise((resolve, reject) => {
    const url = new URL(IRISGO_DEVICE_USAGE_URL);
    if (city !== 0) url.searchParams.append("city", city);
    url.searchParams.append("month", month);
    url.searchParams.append("year", year);

    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (response.status === 401) {
          reject(new Error("ACCESS DENIED"));
        } else {
          response.json().then((data) => {
            resolve(data.result);
          });
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const MOBILE_USAGE_LOOKUP_REQUEST = (token) => {
  return new Promise((resolve, reject) => {
    const url = new URL(IRISGO_LOOKUP_URL);
    url.searchParams.append("city", true);
    url.searchParams.append("device_info", true);
    url.searchParams.append("profile", true);
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (response.status === 401) {
          reject(new Error("ACCESS DENIED"));
        } else {
          response.json().then((data) => {
            if (data.success === true) {
              resolve(data.result);
            } else {
              reject(new Error("Error in city and devices lookup data"));
            }
            resolve(data);
          });
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const MOBILE_DEVICE_CITY_LOOKUP_REQUEST = (token) => {
  return new Promise((resolve, reject) => {
    const url = new URL(IRISGO_LOOKUP_URL);
    url.searchParams.append("city", true);
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (response.status === 401) {
          reject(new Error("ACCESS DENIED"));
        } else {
          response.json().then((data) => {
            if (data.success === true) {
              resolve(data.result);
            } else {
              reject(new Error("Error in city and devices lookup data"));
            }
            resolve(data);
          });
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const IRISGO_USER_PROFILE_UPDATE_REQUEST = (id, data, token) => {
  return new Promise((resolve, reject) => {
    fetch(`${IRISGO_USER_PROFILE_UPDATE_URL}${id}/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ settings: data }),
      // body: JSON.stringify(data),
    })
      .then((result) => {
        if (result.status === 200) {
          resolve(result.json());
        } else {
          reject("User Profile Update Failed");
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/**
 *
 * @summary send API request to the server in order to enabled or disabled a user's accessibility to widgets
 *
 * @param {user} id
 * @param {Object} data
 * @param {String} token
 */
export const IRISGO_UPDATE_USER_PROFILE_REQUEST = (id, data, token) => {
  return new Promise((resolve, reject) => {
    fetch(`${IRISGO_USER_PROFILE_UPDATE_URL}${id}/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      // body: JSON.stringify({ settings: data }),
      body: JSON.stringify(data),
    })
      .then((result) => {
        if (result.status === 200) {
          resolve(result.json());
        } else {
          reject("User Profile Update Failed");
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const createNewUser = async (params, accessToken) => {
  const formData = getFormDataFromObject(params);
  const options = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    body: formData,
  };
  const response = await fetch(IRISGO_CREATE_NEW_USER_URL, options);
  const responseJson = await response.json();
  let error;
  if (response.ok) {
    return responseJson;
  } else {
    if (response.status === 401) {
      error = new Error("access_denied__error_message");
    } else if (response.status === 404) {
      error = new Error("page_not_found_error_message");
    } else if (response.status >= 500) {
      error = new Error("server_error_message");
    } else {
      error = new Error("general_error_message");
    }
  }
  throw error;
};

const getFormDataFromObject = (object) => {
  const formData = new FormData();
  for (const key in object) {
    if (Object.hasOwnProperty.call(object, key)) {
      const element = object[key];
      formData.append(key, element);
    }
  }
  return formData;
};

export const getCityUsers = async (token, cityId) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await fetch(IRISGO_LOOKUP_URL, options);
  const responseJson = await response.json();
  let error;
  if (response.ok) {
    // all users with the same city id
    const usersWithinCity = responseJson.result.user.filter(
      (user) =>
        user.profile &&
        user.profile.city &&
        user.profile.city.id &&
        user.profile.city.id === cityId
    );

    // users that are not defined as "admin"
    const nonAdminUsers = usersWithinCity.filter((user) => {
      const userGroupNames = user.groups.reduce((acc, ele) => {
        return [...acc, ele.name];
      }, []);

      return userGroupNames.indexOf("admin") < 0;
    });
    return nonAdminUsers;
  } else {
    if (response.status === 401) {
      error = new Error("access_denied__error_message");
    } else if (response.status === 404) {
      error = new Error("page_not_found_error_message");
    } else if (response.status >= 500) {
      error = new Error("server_error_message");
    } else {
      error = new Error("general_error_message");
    }
  }
  throw error;
};

export const resetPassword = async (params, token) => {
  const options = {
    method: "POST",
    headers: {
      // "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: getFormDataFromObject(params),
  };
  const response = await fetch(IRISGO_RESET_USER_PASSWORD, options);
  const responseJson = await response.json();
  let error;
  if (response.ok) {
    return responseJson;
  } else {
    if (response.status === 401) {
      error = new Error("access_denied__error_message");
    } else if (response.status === 404) {
      error = new Error("page_not_found_error_message");
    } else if (response.status >= 500) {
      error = new Error("server_error_message");
    } else {
      error = new Error("general_error_message");
    }
  }
  throw error;
};

export const getLabel = async (id, token) => {
  return new Promise((resolve, reject) => {
    fetch(`${IRISGO_LABEL_URL}?id=${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(async (result) => {
        if (result.status === 200) {
          var result_json = await result.json();
          if (result_json.count === 0) {
            reject(`getLabel returned no results for label_id ${id}`);
          } else {
            resolve(result_json.results[0]);
          }
        } else {
          reject(`Label retrieve failed (status ${result.status})`);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateLabel = async (id, token, label) => {
  return new Promise((resolve, reject) => {
    fetch(`${IRISGO_LABEL_URL}/${id}/`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(label),
    })
      .then(async (result) => {
        if (result.status === 200) {
          var result_json = await result.json();
          resolve(result_json.results[0]);
        } else {
          reject(`Label update failed (status ${result.status})`);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateLabelComment = async (
  id,
  token,
  userid,
  datetime,
  comment
) => {
  let body = {
    comment: comment,
    user_id: userid,
    datetime: datetime,
  };
  return new Promise((resolve, reject) => {
    fetch(`${IRISGO_LABEL_URL}/${id}/comments/`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then(async (result) => {
        if (result.status === 200) {
          var result_json = await result.json();
          resolve(result);
        } else {
          reject(`Label comment update failed (status ${result.status})`);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateLabelResolution = async (id, token, resolution_code) => {
  return new Promise((resolve, reject) => {
    fetch(`${IRISGO_LABEL_URL}/${id}/resolution/`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ resolution: resolution_code }),
    })
      .then(async (result) => {
        if (result.status === 200) {
          var result_json = await result.json();
          resolve(result);
        } else {
          reject(`Label resolution update failed (status ${result.status})`);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const addLabelComment = async (id, token, comment, user_id) => {
  // add label comment function
};

/**
 * @summary send request to query the look up table on the server
 *
 * @description send request to the look up table to query desired properties,
 * whose names are passed as an array of strings.
 *
 * ie: if oen needs the users and profile from the look up table, simple pass
 * ["user", "profile"] and values of these two fields would be returned
 *
 * @param {String} token
 * @param {Array} keys Array of properties that the look request would return
 */
export const irisLookup = async (token, keys) => {
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  const url = new URL(IRISGO_LOOKUP_URL);
  // selectively query desired fields from the look up table
  if (Array.isArray(keys) && keys.length > 0) {
    for (const key of keys) {
      url.searchParams.append(key, true);
    }
  }
  const response = await fetch(url, options);
  const responseJson = await response.json();
  let error;
  if (response.ok) {
    return responseJson;
  } else {
    if (response.status === 401) {
      error = new Error("access_denied__error_message");
    } else if (response.status === 404) {
      error = new Error("page_not_found_error_message");
    } else if (response.status >= 500) {
      error = new Error("server_error_message");
    } else {
      error = new Error("general_error_message");
    }
  }
  throw error;
};

export const requestNewAccount = async (formData) => {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(formData),
  };
  const response = await fetch(REQUEST_NEW_ACCOUNT_URL, options);
  const responseJson = await response.json();
  let error;
  if (response.ok) {
    return responseJson;
  } else {
    if (response.status === 401) {
      error = new Error("access_denied__error_message");
    } else if (response.status === 404) {
      error = new Error("page_not_found_error_message");
    } else if (response.status >= 500) {
      error = new Error("server_error_message");
    } else {
      error = new Error("general_error_message");
    }
  }
  throw error;
};

export const getLast30DaysWorkOrders = async (region, timezone, token) => {
  const d = new Date();
  const [startDateUTCString, endDateUTCString] =
    DateUtils.momentGetUTCDateRangeOf(d, -30, timezone);
  const param = {
    region: {
      operant: "eq",
      value0: region,
    },
    open_datetime: {
      operant: "between",
      value0: startDateUTCString,
      value1: endDateUTCString,
    },
  };
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(param),
  };
  return new Promise(async (resolve, reject) => {
    let error;

    const response = await fetch(IRIS_WORK_ORDER_END_POINT, options).catch(
      (e) => {
        error = e;
      }
    );

    if (error) {
      // caught error due to fetch API
      reject(error);
    } else if (response.ok) {
      const responseJson = await response.json();
      resolve(responseJson.data);
    } else {
      const { status } = response;
      const message = await response.text();
      const errorMessage = `Error status: message`;
      if (status === 401 || status === 403) {
        error = new Error("ACCESS DENIED");
      } else if (status === 404) {
        error = new Error("PAGE NOT FOUND");
      } else if (status >= 500) {
        error = new Error("SERVER ERROR");
      } else {
        error = new Error("UNKNOWN ERROR");
      }
      reject(error);
    }
    // resolve(work_orders);
  });
};
