import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Amplify from "aws-amplify";
// import { awsconfig } from "./aws/aws-exports";
import { awsconfig } from "./aws/aws-exports";
import { setDefaultOptions } from "esri-loader";
import "./config/i18n";

import moment from "moment";
import fr from "components/language/moment/locales/fr";
moment.locale("fr", fr);

Amplify.configure(awsconfig);

setDefaultOptions({
  version: "4.25", // since Nov,2022
  css: true,
});
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
