import React, { useEffect, useRef } from "react";
import useAlert from "../hoc/useAlert";

import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    width: 500,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));
const AlertModal = () => {
  const { message = "", isOpen = false, dismiss } = useAlert();
  const confirmBtn = useRef();
  const classes = useStyles();

  useEffect(() => {
    let timeoutFn;
    if (isOpen) {
      timeoutFn = setTimeout(() => {
        if (confirmBtn.current) {
          confirmBtn.current.focus();
          timeoutFn = null;
        }
      }, 300);
    } else {
      if (timeoutFn) {
        clearTimeout(timeoutFn);
        timeoutFn = null;
      }
    }
  }, [isOpen]);

  return (
    <div>
      <Modal
        aria-labelledby="confirm-modal-title"
        aria-describedby="confirm-modal-description"
        className={classes.modal}
        open={isOpen}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isOpen}>
          <div className={classes.paper}>
            <h2 id="confirm-modal-title">Alert</h2>
            <p id="confirm-modal-description">{message}</p>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                ref={confirmBtn}
                variant="contained"
                color="primary"
                onClick={dismiss}
              >
                Okay
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default AlertModal;
