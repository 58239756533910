import {
  useReducer,
  useEffect,
  useCallback,
  useContext,
  useState,
} from "react";
import {
  Button,
  IconButton,
  InputAdornment,
  makeStyles,
  MenuItem,
  Switch,
  TextField,
} from "@material-ui/core";

import useGeoLocation from "../../customHooks/useGeoLocation";
import EditIcon from "@material-ui/icons/Edit";
import LoopIcon from "@material-ui/icons/Loop";
import MuiSelect from "../../irisUI/inputs/MuiSelect";
import { getCurrentWeather } from "../../utils/openWeatherAPI/openWeatherAPI";
import { getLabelCityData } from "../../utils/api/uploadImageAPI";
import {
  sendRoadCondition,
  togglePatrolRequest,
} from "../../utils/api/patrolAPI";
import { AuthContext } from "../../components/AuthContext";
import useAlert from "../../irisUI/alert/hoc/useAlert";
import { CURRENT_PATROLER } from "utils/env/localStorageKeys";

import { MDSSContext } from "context/MDSSContext";

import WeatherForcecastView from "components/maps/weatherForcecast/WeatherForcecastView";
import useAuth from "auth/hook/useAuth";
import { CREATE_LANG_KEY, TRANSLATE } from "utils/langauge/translate";
import { TRANSLATE_ONCE } from "utils/langauge/translate";
import { useTranslation } from "react-i18next";
import { ManualPatrolFormTranslate } from "./ManualPatrolForm.translate";
import { CommonTranslate } from "components/language/common.translation";
import { getDeviceByClientID } from "auth/hook/api/request";
import { Auth } from "aws-amplify";

const useStyle = makeStyles(() => ({
  wrapper: {
    height: "100%",
    width: "100%",
    position: "relative",
  },

  root: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    boxSizing: "border-box",
    border: "1px solid black",
    maxWidth: 400,
    // height: "auto",
    width: "80%",
    padding: "1em 0",
    // height: "80%",
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },

  inputs: {
    marginTop: "1.3rem",
    width: 200,
  },

  buttonDiv: {
    marginTop: "1.5rem",
  },
}));

const namesObj = [
  { id: "1", name: "Adams" },
  { id: "2", name: "Haseeb" },
  { id: "3", name: "Kevin" },
  { id: "4", name: "Lucien" },
  { id: "5", name: "Ye" },
];

const ROAD_CONDITIONS = [
  { name: "RBD - BARE & DRY", id: "1" },
  { name: "RDW - DAMP / WET", id: "2" },
  { name: "RSP - ROAD SNOW PACKED", id: "3" },
  { name: "RCB - ROAD CENTRE BARE", id: "4" },
  { name: "RSC - ROAD SNOW CONVERAGE BY CM", id: "5" },
  { name: "RSL - ROAD SLUSHY", id: "6" },
  { name: "RI - ROAD ICY", id: "7" },
  { name: "RSD - ROAD SNOW DRIFTING", id: "8" },
  { name: "WTB - WHEEL TRACK BARE", id: "9" },
];

const SHIFTS = [
  { id: "Morning", name: "Morning" },
  { id: "Afternoon", name: "Afternoon" },
  { id: "Evening", name: "Night" },
];

const initialState = {
  devices: [],
  patroller: null,
  patrollerShift: "",
  weatherCondition: "",
  editMode: false,
  snowAccumuation: "0",
  patrolDevice: null,
  isWinterPatrol: false,
  visibility: "Good",
  temperature: "0",
  roadCondition: null,
  routeCanadidate: { name: "None", id: 1 },
  routesSelection: [],
};

const ACTIONS = {
  SET_PATROLER_NAME: "SET PATROLER NAME",
  SET_DEVICES: "SET DEVICE",
  SET_PATROL_SHIFT: "SET PATROL SHIFT",
  SET_WEATHER_CONDITION: "SET WEATHER CONDITION",
  SET_EDIT_MODE: "SET EDIT MODE",
  SET_SNOW_ACCUMULATION: "SET SNOW ACCUMULATION",
  SET_PATROL_DEVICE: "SET PATROL DEVICE",
  SET_IS_WINTER_PATROL: "SET IS WINTER PATROL",
  SET_VISIBILITY: "SET VISIBILITY",
  SET_TEMPERATURE: "SET TEMPERATURE",
  SET_ROAD_CONDITION: "SET ROAD CONDITION",
  SELECT_ROUTES: "SELECT ROUTES",
  RESET: "RESET",
};

const reducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case ACTIONS.SET_PATROLER_NAME:
      return { ...state, patroller: payload };
    case ACTIONS.SET_DEVICES:
      return { ...state, devices: payload };
    case ACTIONS.SET_PATROL_SHIFT:
      return { ...state, patrollerShift: payload };
    case ACTIONS.SET_WEATHER_CONDITION:
      return { ...state, weatherCondition: payload };
    case ACTIONS.SET_EDIT_MODE:
      return { ...state, editMode: payload };
    case ACTIONS.SET_SNOW_ACCUMULATION:
      return { ...state, snowAccumuation: payload };
    case ACTIONS.SET_PATROL_DEVICE:
      return { ...state, patrolDevice: payload };
    case ACTIONS.SET_IS_WINTER_PATROL:
      return { ...state, isWinterPatrol: payload };
    case ACTIONS.SET_VISIBILITY:
      return { ...state, visibility: payload };
    case ACTIONS.SET_TEMPERATURE:
      return { ...state, temperature: payload };
    case ACTIONS.SET_ROAD_CONDITION:
      return { ...state, roadCondition: payload };
    case ACTIONS.SELECT_ROUTES:
      return { ...state, routesSelection: payload };
    case ACTIONS.SET_SELECTED_ROUTES:
      console.log(payload);
      return { ...state, routeCanadidate: payload };

    case ACTIONS.RESET:
      return {
        ...initialState,
        devices: state.devices,
      };

    default:
      return initialState;
  }
};

export default function ManualPatrolForm(props) {
  const { i18n } = useTranslation();
  const {
    pageConfig: {
      city: { code, name: cityName, degree_preference },
      patrolConfig: {
        deviceList = [],
        shift: shifts = SHIFTS,
        additionalSetting,
      } = {},
    },
    user,
    client,
  } = useAuth();
  // styles from MUI
  const { root, inputs, buttonDiv, wrapper } = useStyle();

  const {
    handleUpdatePatrolType,
    handleShowRoadCondition,
    handleShowRoadAlert,
  } = useContext(MDSSContext);

  const {
    contextUsername: patroller,
    contextToken,
    setDeviceInfo,
    setLabels,
    setPatrolInfo,
    setRoutesInfo,
    setFilterExpression,
  } = useContext(AuthContext);

  const { showAlert } = useAlert();

  const [state, dispatch] = useReducer(reducer, initialState);

  const [isLoading, setIsLoading] = useState(false);

  const [lastPatrolRecord] = useState(
    JSON.parse(localStorage.getItem(CURRENT_PATROLER))
  );

  const {
    devices,
    patrollerShift,
    weatherCondition,
    editMode,
    snowAccumuation,
    patrolDevice,
    isWinterPatrol,
    visibility,
    temperature,
    roadCondition,
    routeCanadidate,
    routesSelection,
  } = state;

  const {
    data: { latitude, longitude },
    loading,
    error,
  } = useGeoLocation();

  const getWeather = useCallback(() => {
    getCurrentWeather(code, degree_preference).then((result) => {
      const { condition, temp } = result;
      dispatch({ type: ACTIONS.SET_WEATHER_CONDITION, payload: condition });
      dispatch({ type: ACTIONS.SET_TEMPERATURE, payload: Math.round(temp) });
    });
  }, [code]);

  // get weather info on mounted
  useEffect(() => {
    getWeather();

    let shift = shifts[0].name;

    dispatch({ type: ACTIONS.SET_PATROL_SHIFT, payload: shift });
  }, [getWeather, shifts]);

  // get routes info
  useEffect(() => {
    let avaiableRoutes = additionalSetting?.routes?.map((route, index) => {
      const [name, queryExpression] = route;
      return { name: name, value: queryExpression, id: ++index };
    });
    dispatch({ type: ACTIONS.SELECT_ROUTES, payload: avaiableRoutes });
  }, [setRoutesInfo]);

  useEffect(async () => {
    try {
      const cognitoToken = await Auth.currentAuthenticatedUser();
      const {
        result: { label_type, city },
      } = await getLabelCityData(contextToken);

      let cityId = null;
      Object.keys(city).forEach((key) => {
        const cityData = city[key];
        if (cityData.name.toLowerCase() === cityName.toLowerCase())
          cityId = Number(key);
      });

      const accessToken = cognitoToken.signInUserSession.accessToken.jwtToken;
      // getDeviceByClientID(accessToken, 18).then(result => { // For testing
      const deviceResult = await getDeviceByClientID(accessToken, client.id);

      const tags = ["TEST - Lucien", "Haseeb", "Kevin-Test"];
      const cityDevices = deviceResult
        .filter(
          (device) =>
            !device.tag.toLowerCase().includes("manual") &&
            !device.tag.toLowerCase().includes("sidewalk") &&
            device.enabled
        )
        // .filter(device => tags.indexOf(device.tag) > -1) // For testing
        .map((device) => {
          return {
            tag: device.tag,
            device_sn: device.device_sn,
            id: device.id,
            idV2: device.idv2,
            cityId: cityId,
          };
        })
        .sort((a, b) => {
          const aTag = a.tag;
          const bTag = b.tag;
          if (aTag > bTag) return 1;
          else if (aTag < bTag) return -1;
          return 0;
        });

      if (lastPatrolRecord) {
        const { patroler_name, device_sn } = lastPatrolRecord;

        const matchedDevice = cityDevices.find(
          (device) => device.device_sn === device_sn
        );

        if (matchedDevice) {
          dispatch({
            type: ACTIONS.SET_PATROL_DEVICE,
            payload: matchedDevice,
          });
          setDeviceInfo(matchedDevice);
        }

        const matchedPatroller = namesObj.find(
          (obj) => obj.name === patroler_name
        );
        if (matchedPatroller) {
          dispatch({
            type: ACTIONS.SET_PATROLER_NAME,
            payload: matchedPatroller,
          });
        }
      }
      dispatch({ type: ACTIONS.SET_DEVICES, payload: cityDevices });
      const validtypes = label_type.filter(
        ({ enabled, city_id }) =>
          enabled.toLowerCase() === "y" &&
          city_id.indexOf(cityDevices[0].cityId) > -1 // TODO read this value from iris-auth
      );
      setLabels(validtypes);

      const snow_accumulation = lastPatrolRecord?.snow_accumulation || "0";
      const patrol_type = lastPatrolRecord?.patrol_type || "0";

      dispatch({
        type: ACTIONS.SET_SNOW_ACCUMULATION,
        payload: snow_accumulation,
      });
      // initial state is set to be summer, instead of being the same as local storage
      // dispatch({
      //   type: ACTIONS.SET_IS_WINTER_PATROL,
      //   payload: patrol_type === "winter",
      // });
    } catch (error) {
      console.error(error);
    }
    return () => {
      // clean up function
    };
  }, [contextToken, setLabels, lastPatrolRecord, cityName, setDeviceInfo]);

  if (loading) {
    return <div className={root}>Loading location ...</div>;
  } else if (error) {
    return (
      <div className={root}>
        {error.message}
        <a
          href="https://www.badgermapping.com/knowledgebase/turn-geolocation-safari-ipad/"
          rel="noreferrer"
          target="_blank"
        >
          enable geo location on your device
        </a>
        and refresh the page
      </div>
    );
  }

  const handleStartPatrol = async () => {
    setDeviceInfo(patrolDevice);
    setIsLoading(true);
    const patrol_type = isWinterPatrol ? "winter" : "summer";

    //isWinterPatrol to determine if show mdss on the map or not
    if (isWinterPatrol) {
      handleUpdatePatrolType("winter");
    } else if (!isWinterPatrol) {
      handleUpdatePatrolType("summer");
    }

    const currentDeviceSn = patrolDevice.device_sn;

    const roadCondtionName = roadCondition.name;

    const routesSelectionName = routeCanadidate.name;

    const weatherString = `weather condition: ${weatherCondition}; visibility: ${visibility}; temperature: ${temperature}; snow accumuation: ${
      isWinterPatrol ? Number(snowAccumuation) : 0
    }`;
    const newRecord = {
      patroler_name: patroller,
      device_sn: currentDeviceSn,
      shift: patrollerShift,
      weather: weatherString,
      patrol_type,
      road_condition: roadCondition,
      routes_selection: routesSelectionName,
      snow_accumulation: isWinterPatrol ? snowAccumuation : "0",
      was_in_patrol: true,
    };
    /** saved patroller in local storage */
    const savedPatrolRecord = JSON.parse(
      localStorage.getItem(CURRENT_PATROLER)
    );

    if (savedPatrolRecord) {
      const {
        patroler_name: saved_patroller_name,
        patrol_type: saved_patrol_type,
        device_sn: saved_device_sn,
      } = savedPatrolRecord;
      if (
        saved_patroller_name !== patroller ||
        saved_patrol_type !== patrol_type ||
        saved_device_sn !== currentDeviceSn
      ) {
        newRecord.was_in_patrol = false;
        const stopPreviousPatrolResponse = await togglePatrolRequest(
          contextToken,
          savedPatrolRecord,
          false
        ).catch((error) => {
          console.error(
            "ERROR in stopping previous record's patrol:",
            error,
            "previous record:",
            savedPatrolRecord,
            "new Record: ",
            newRecord
          );
        });

        if (stopPreviousPatrolResponse?.success) {
          process.env.NODE_ENV === "development" &&
            console.log(
              "stopped previous patrol SUCCESSFULLY",
              "previous record:",
              savedPatrolRecord,
              "new Record: ",
              newRecord
            );
        }
      }
    }
    // start by sending road condition first
    try {
      let errorMessage = "";
      const roadConditionResponse = await sendRoadCondition(
        contextToken,
        currentDeviceSn,
        roadCondtionName
      ).catch((error) => {
        errorMessage = error.message;
        console.error("ERROR in sending road condition: ", error.message);
      });
      if (roadConditionResponse) {
        const { success } = roadConditionResponse;
        // send road condition successfully, load patrol page directly directly
        if (success === true) {
          localStorage.setItem(CURRENT_PATROLER, JSON.stringify(newRecord));
          props.history.push("/patrol");
        } else {
          await showAlert("Error: " + errorMessage);
        }
      } else if (errorMessage) {
        // send road condition failed and device is found not in patrol
        // mark it as in patrol first
        if (errorMessage.includes("is not in patrol")) {
          const togglePatrolResponse = await togglePatrolRequest(
            contextToken,
            { ...newRecord, confirm_road_condition: patrol_type === "winter" },
            true
          ).catch((error) => {
            alert(error.message);
          });
          // device is marked as in patrol, send road condition again
          if (togglePatrolResponse.success) {
            const roadConditionResponse = await sendRoadCondition(
              contextToken,
              currentDeviceSn,
              roadCondtionName
            );

            // road condition is sent, load patrol page
            if (roadConditionResponse.success) {
              localStorage.setItem(CURRENT_PATROLER, JSON.stringify(newRecord));
              props.history.push("/patrol");
            }
          }
        } else {
          await showAlert("Error: " + errorMessage);
        }
      }
    } catch (error) {
      alert("Error in handling start patrol: " + error.message);
    }
  };
  const SeasonSwitch = (props) => {
    const { onChange, checked } = props;
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
        className={inputs}
      >
        <div>{TRANSLATE(CommonTranslate.SUMMER.toString())}</div>
        <div>
          <Switch name="checkedC" checked={checked} onChange={onChange} />
        </div>
        <div>{TRANSLATE(CommonTranslate.WINTER.toString())}</div>
      </div>
    );
  };

  return (
    <div className={wrapper}>
      <form className={root} onSubmit={(event) => event.preventDefault()}>
        <p style={{ fontSize: "1.2rem" }}>
          {i18n.language === "en" && <span>Good</span>}{" "}
          {TRANSLATE(
            CREATE_LANG_KEY(patrollerShift.toLowerCase(), null, "COMMON")
          )}{" "}
          <b>{patroller}</b>{" "}
        </p>
        {/* <div className={inputs}>
          <TextField value={contextUsername} disabled={true} fullWidth />
        </div> */}
        <div className={inputs}>
          <MuiSelect
            value={patrollerShift}
            label={TRANSLATE_ONCE(ManualPatrolFormTranslate.SHIFT.toString())}
            values={shifts}
            labelField="name"
            valueField="id"
            renderValue={(ele, index) => (
              <MenuItem key={index} value={ele.name}>
                {TRANSLATE(CREATE_LANG_KEY(ele.name, null, "COMMON"), ele.name)}
              </MenuItem>
            )}
            onSelect={(selected) => {
              const { id } = selected;
              dispatch({ type: ACTIONS.SET_PATROL_SHIFT, payload: id });
            }}
            fullWidth
          />
        </div>
        {devices.length > 0 && (
          <div className={inputs}>
            <MuiSelect
              label={TRANSLATE_ONCE(
                ManualPatrolFormTranslate.DEVICE.toString()
              )}
              value={patrolDevice?.device_sn}
              values={devices}
              labelField="tag"
              valueField="device_sn"
              onSelect={(selected) => {
                dispatch({
                  type: ACTIONS.SET_PATROL_DEVICE,
                  payload: selected,
                });
                setDeviceInfo({ ...selected });
              }}
              fullWidth
            />
          </div>
        )}
        {Array.isArray(routesSelection) > 0 && (
          <div className={inputs}>
            <MuiSelect
              label={TRANSLATE_ONCE(
                ManualPatrolFormTranslate.ROUTES.toString()
              )}
              value={routeCanadidate}
              values={routesSelection}
              labelField="name"
              valueField="name"
              onSelect={(selected) => {
                dispatch({
                  type: ACTIONS.SET_SELECTED_ROUTES,
                  payload: selected,
                });
                setFilterExpression(selected.value);
              }}
              fullWidth
              menuMaxHeight={300}
            />
          </div>
        )}
        <RoadConditionSelection
          className={inputs}
          onChange={(selection) => {
            dispatch({
              type: ACTIONS.SET_ROAD_CONDITION,
              payload: selection,
            });
          }}
        />
        <div className={inputs}>
          <TextField
            label={TRANSLATE_ONCE(
              ManualPatrolFormTranslate.WEATHER_CONDITION.toString()
            )}
            placeholder={TRANSLATE_ONCE(
              ManualPatrolFormTranslate.WEATHER_CONDITION.toString()
            )}
            value={weatherCondition}
            disabled={!editMode}
            onChange={({ target: { value } }) =>
              dispatch({ type: ACTIONS.SET_WEATHER_CONDITION, payload: value })
            }
            InputProps={{
              endAdornment: !editMode ? (
                <IconButton
                  size="small"
                  onClick={() =>
                    dispatch({ type: ACTIONS.SET_EDIT_MODE, payload: true })
                  }
                >
                  <EditIcon />
                </IconButton>
              ) : (
                <IconButton
                  size="small"
                  onClick={() => {
                    getWeather();
                    dispatch({ type: ACTIONS.SET_EDIT_MODE, payload: false });
                  }}
                >
                  <LoopIcon />
                </IconButton>
              ),
            }}
            fullWidth
          />
        </div>
        <div className={inputs}>
          <TextField
            label={TRANSLATE_ONCE(
              ManualPatrolFormTranslate.VISIBILITY.toString()
            )}
            placeholder={TRANSLATE_ONCE(
              ManualPatrolFormTranslate.VISIBILITY.toString()
            )}
            value={visibility}
            disabled={!editMode}
            onChange={({ target: { value } }) =>
              dispatch({ type: ACTIONS.SET_VISIBILITY, payload: value })
            }
            InputProps={{
              endAdornment: !editMode ? (
                <IconButton
                  size="small"
                  onClick={() =>
                    dispatch({ type: ACTIONS.SET_EDIT_MODE, payload: true })
                  }
                >
                  <EditIcon />
                </IconButton>
              ) : (
                <IconButton
                  size="small"
                  onClick={() => {
                    getWeather();
                    dispatch({ type: ACTIONS.SET_EDIT_MODE, payload: false });
                  }}
                >
                  <LoopIcon />
                </IconButton>
              ),
            }}
            fullWidth
          />
        </div>
        <div className={inputs}>
          <TextField
            label={
              TRANSLATE_ONCE(ManualPatrolFormTranslate.TEMPERATURE.toString()) +
              ` (°${degree_preference})`
            }
            placeholder={TRANSLATE_ONCE(
              ManualPatrolFormTranslate.TEMPERATURE.toString()
            )}
            value={temperature}
            disabled={!editMode}
            onChange={({ target: { value } }) =>
              dispatch({ type: ACTIONS.SET_TEMPERATURE, payload: value })
            }
            InputProps={{
              type: "number",

              endAdornment: !editMode ? (
                <IconButton
                  size="small"
                  onClick={() =>
                    dispatch({ type: ACTIONS.SET_EDIT_MODE, payload: true })
                  }
                >
                  <EditIcon />
                </IconButton>
              ) : (
                <IconButton
                  size="small"
                  onClick={() => {
                    getWeather();
                    dispatch({ type: ACTIONS.SET_EDIT_MODE, payload: false });
                  }}
                >
                  <LoopIcon />
                </IconButton>
              ),
            }}
            fullWidth
          />
        </div>

        <SeasonSwitch
          checked={isWinterPatrol}
          onChange={(event, isChecked) => {
            dispatch({
              type: ACTIONS.SET_IS_WINTER_PATROL,
              payload: isChecked,
            });
            if (isChecked) {
              handleUpdatePatrolType("winter");
              handleShowRoadCondition(true);
            } else if (!isChecked) {
              handleUpdatePatrolType("summer");
              handleShowRoadCondition(false);
            }
          }}
        />

        {isWinterPatrol && (
          <div className={inputs}>
            <TextField
              type="number"
              label={TRANSLATE_ONCE(
                ManualPatrolFormTranslate.SNOW_ACCUMULATION.toString()
              )}
              placeholder={TRANSLATE_ONCE(
                ManualPatrolFormTranslate.SNOW_ACCUMULATION.toString()
              )}
              value={snowAccumuation}
              disabled={!isWinterPatrol}
              onChange={({ target: { value } }) => {
                let numberValue = Number(value) || 0;

                if (numberValue < 0) {
                  numberValue = 0;
                }
                dispatch({
                  type: ACTIONS.SET_SNOW_ACCUMULATION,
                  payload: numberValue + "",
                });
              }}
              InputProps={{
                endAdornment: <InputAdornment>cm</InputAdornment>,
              }}
              fullWidth
            />
          </div>
        )}

        <div className={buttonDiv}>
          <Button
            color="primary"
            variant="contained"
            disabled={
              !patroller ||
              patrollerShift === "" ||
              weatherCondition === "" ||
              !patrolDevice ||
              isLoading ||
              !roadCondition
            }
            endIcon={
              isLoading && <i className="fa fa-circle-o-notch fa-spin" />
            }
            onClick={handleStartPatrol}
          >
            {TRANSLATE(ManualPatrolFormTranslate.START_PATROL.toString())}
          </Button>
        </div>
      </form>
      <WeatherForcecastView page="form" />
    </div>
  );
}

export const RoadConditionSelection = ({
  className,
  style,
  value,
  onChange,
}) => {
  const [selecteValue, setSelecteValue] = useState(value);

  useEffect(() => {
    setSelecteValue(value);
  }, [value]);

  return (
    <div className={className} style={style}>
      <MuiSelect
        style={{ margin: 0 }}
        label={TRANSLATE_ONCE(
          ManualPatrolFormTranslate.ROAD_CONDITION.toString()
        )}
        labelField="name"
        value={selecteValue}
        valueField="id"
        values={ROAD_CONDITIONS}
        renderValue={(ele, index) => (
          <MenuItem key={index} value={ele.id}>
            {TRANSLATE(CREATE_LANG_KEY(ele.name, null, "MANUAL_PATROL_FORM"))}
          </MenuItem>
        )}
        onSelect={onChange}
        fullWidth
      />
    </div>
  );
};
