import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { getCurrentWeather } from "../../utils/openWeatherAPI/openWeatherAPI";
import { remapClientName } from "utils/utils";
// import moment from "moment-timezone";

const HEIGHT = 70;

/**5 minutes */
const REFRESH_WEATHER_INFO_INTERVAL = 5 * 60 * 1000;
const useStyles = makeStyles((theme) => ({
  root: {
    height: HEIGHT,
  },
  subtitle: {
    marginLeft: "5px",
  },
  weatherIcon: { height: HEIGHT, paddingBottom: "10px" },

  weatherText: {
    "@media (max-width: 850px)": {
      fontSize: "85%",
    },
  },
}));

export default function WeatherView({
  cityCode,
  province,
  cityName,
  degreePreference,
}) {
  const [weatherInfo, setWeatherInfo] = useState([]);
  const [visibility, setVisibility] = useState(false);
  const classes = useStyles(visibility);

  useEffect(() => {
    let isSubscribed = true;
    function getWeather() {
      getCurrentWeather(cityCode, degreePreference)
        .then((result) => {
          if (isSubscribed) {
            setWeatherInfo(result);
            setVisibility(true);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
    let interval = setInterval(() => {
      getWeather();
    }, REFRESH_WEATHER_INFO_INTERVAL);

    getWeather();
    return () => {
      isSubscribed = false;

      if (interval) {
        clearInterval(interval);
        interval = null;
      }
    };
  }, [cityCode]);

  const parsedClientName = remapClientName(cityName);

  return (
    <Grid container className={classes.root} alignItems="center">
      <Grid item>
        <Typography align="right" variant="h6" className={classes.weatherText}>
          {visibility
            ? `${Math.round(weatherInfo.temp)}°${degreePreference}`
            : "--"}
        </Typography>
        <Typography
          align="right"
          variant="body1"
          className={classes.weatherText}
        >
          {visibility ? weatherInfo.condition : "unknown"}
        </Typography>
      </Grid>
      <Grid item>
        {visibility ? (
          <img
            src={`https://openweathermap.org/img/wn/${weatherInfo.icon}@2x.png`}
            alt={weatherInfo.condition}
            className={classes.weatherIcon}
          />
        ) : null}
      </Grid>
      <Grid item>
        {visibility ? (
          <>
            <Typography variant="body1" className={classes.weatherText}>
              {parsedClientName}
            </Typography>
            <Typography variant="body1" className={classes.weatherText}>
              {province}
            </Typography>
          </>
        ) : (
          <Typography variant="body1"> City, Province</Typography>
        )}
      </Grid>
    </Grid>
  );
}
