import React, { useEffect } from "react";
import useConfirm from "../hoc/useConfirm";
import { makeStyles } from "@material-ui/core/styles";

import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    width: 500,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));
const ConfirmModal = () => {
  const { message = "", isOpen = false, proceed, cancel } = useConfirm();

  const classes = useStyles();

  useEffect(() => {
    const handleKeydown = (e) => {
      if (proceed && isOpen && e.key === "Enter") {
        proceed();
      }
    };

    window.addEventListener("keydown", handleKeydown);
    return () => window.removeEventListener("keydown", handleKeydown);
  }, [proceed, isOpen]);

  return (
    <div>
      <Modal
        aria-labelledby="confirm-modal-title"
        aria-describedby="confirm-modal-description"
        className={classes.modal}
        open={isOpen}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isOpen}>
          <div className={classes.paper}>
            <h2 id="confirm-modal-title">Confirm</h2>
            <p id="confirm-modal-description">{message}</p>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button variant="contained" color="primary" onClick={proceed}>
                Okay
              </Button>
              <Button
                variant="contained"
                color="secondary"
                style={{ marginLeft: "1rem" }}
                onClick={cancel}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default ConfirmModal;
