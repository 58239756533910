import { IconButton, makeStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
const useStyle = makeStyles((theme) => ({
  root: {
    // position: "absolute",
    // top: "1rem",
    // right: "1rem",
    border: `3px solid ${theme.palette.secondary.main}`,
    padding: 0,

    "&:hover": {
      background: "rgba(255, 255, 255, 0.8)",
    },
  },

  icon: {
    fontSize: "3rem",
  },
}));
export default function ModalCloseButton({
  onClick,
  className = "",
  style = {},
}) {
  const { root, icon } = useStyle();

  return (
    <div className={className} style={style}>
      <IconButton className={root} onClick={onClick} color="secondary">
        <CloseIcon className={icon} />
      </IconButton>
    </div>
  );
}
