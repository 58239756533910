import { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../components/AuthContext";
import makeStyles from "@material-ui/core/styles/makeStyles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";
import welcomeImg from "../assets/WelcomeIMG.jpg";
import IrisCityLogo from "../assets/IrisCityLogo.png";
import irisLogo from "../assets/irisLogo.png";
import withModal from "../HOC/withModal";
import { USER_NAME } from "../utils/env/localStorageKeys";

import esriLogo from "../assets/esriLogo.png";
import geotabLogo from "../assets/geotabLogo.jpg";
import { PROTECTED_ROUTES } from "../routes/data/protectedRoutes";

import pkg from "../../package.json";
import Auth from "@aws-amplify/auth";
import {
  AWS_CHALLENGE_NAMES,
  AWS_PASSWORD_MAX_LENGTH,
  getUserFromSession,
} from "aws/utils";
import { ResetPasswordModal } from "./modals/ResetPasswordModal";
import MuiSnackbar from "irisUI/Snackbar/Snackbar";
import { Button } from "@material-ui/core";
import useAuth from "auth/hook/useAuth";
import { LoginTranslate } from "./Login.translation";
import { TRANSLATE, TRANSLATE_ONCE } from "utils/langauge/translate";

const PASSWORD_MIN_LENGTH = 6;
const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  loginPanel: {
    position: "relative",
    padding: "10px",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    // overflow: "auto",
  },

  logoAndTitles: {},
  loginTextFieldsAndButton: {
    margin: "2rem 0",
    flexGrow: 1,
    // "@media (max-width:(960px)": {
    //   margin: 0,
    // },
    "@media screen and (max-width: 960px)": {
      margin: 0,
    },
  },
  loginFormFooter: {
    "& a": {
      textDecoration: "none",
      margin: "0.375rem 0",
      color: "currentColor",
      fontSize: "1.15rem",
      fontWeight: 500,
      "&:hover": {
        color: "#c1151f",
      },
    },
  },
  loginForm: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    margin: "auto 0",
    width: "55%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "& > *": {
      textAlign: "center",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      "& > *": {
        width: "100%",
      },
    },
  },

  loginFooter: {
    maxHeight: "20vh",
    position: "absolute",
    bottom: 0,
    textAlign: "center",
  },
  welcomeImg: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  irisLogo: {
    position: "absolute",
    width: "120px",
    top: "1.5%",
    right: "1.5%",
  },
  loginLogo: {
    objectFit: "contain",
    width: "70%",
  },
  imagePanel: {
    position: "relative",
  },

  inputText: {
    // margin: "0.375rem 0",
    fontSize: "1.15rem",
    textAlign: "center",
  },

  loginButton: {
    border: "none",
    marginTop: "0.375rem",
    padding: "0.375rem",
    borderRadius: "3px",
    fontWeight: "bold",
    fontSize: "1.15rem",
    backgroundColor: "#ffffff",
    width: 120,
    marginLeft: "auto",
    "&:focus": {
      outline: "none",
    },

    "&:enabled": {
      backgroundColor: "#c1151f",
      color: "#ffffff",
    },
    "&:hover": {
      cursor: "pointer",
      "&:enabled": {
        backgroundColor: "#A80006",
        // color: "#ffffff",
      },
    },
  },

  createAccountButton: {
    border: "none",
    marginTop: "0.375rem",
    padding: "0.375rem",
    borderRadius: "3px",
    fontWeight: 600,
    fontSize: "1.15rem",
    backgroundColor: "#ffffff",
    "&:focus": {
      outline: "none",
    },
    "&:hover": {
      cursor: "pointer",
      color: "#c1151f",
    },
  },

  cooperatorLogo: {
    width: "80%",
    // "@media screen and (max-width: 960px)": {
    //   width: "80%",
    // },
    "@media screen and (max-width: 600px)": {
      width: "40%",
    },
  },

  resetPassword: {
    justifySelf: "center",
    padding: "0.3rem 0",
  },
  resetPasswordLink: {
    color: "#000000",
    textDecoration: "none",
    "&:hover": {
      fontWeight: 700,
      textDecoration: "underline",
    },
  },
}));

function Login(props) {
  const { history } = props;
  const { openModal } = props;
  const classes = useStyles();

  const { login, loading, user, tempUser, resetTempUser, error } = useAuth();

  const [username, setUsername] = useState(
    localStorage.getItem(USER_NAME) || ""
  );
  const [password, setPassword] = useState("");

  const [resetPasswordInfo, setResetPasswordInfo] = useState({
    message: "",
    open: false,
    severity: "success",
    position: "top-center",
  });

  // state(s) for terms of use popup
  const { setContextUsername, setContextToken } = useContext(AuthContext);

  const [shouldDisableLogin, setShouldDisableLogin] = useState(true);

  const handleLogin = async () => {
    // do nothing if password is too short
    // this prevent login process is executed when user press "Enter" on the pass world field
    if (shouldDisableLogin && !props.match.params.auth) {
      return;
    }

    login(username, password);
  };

  useEffect(() => {
    if (user) {
      const { username } = user;
      setContextUsername(username);
      setContextToken("Basic aXJpc19hZG1pbjpTb3EwMjgzcyE=");
      localStorage.setItem(USER_NAME, username);

      history.replace(PROTECTED_ROUTES.patrolForm.path);
    }
  }, [user, history, setContextToken, setContextUsername]);

  useEffect(() => {
    const message = error?.message;
    if (message) {
      openModal("Login Error", message);
    }
  }, [error, openModal]);

  useEffect(() => {
    if (props.match.params.auth) {
      handleLogin();
    }
  });

  useEffect(() => {
    const result = !(
      username.trim().length !== 0 &&
      password.trim().length >= PASSWORD_MIN_LENGTH
    );
    setShouldDisableLogin(result);
  }, [username, password, setShouldDisableLogin]);

  useEffect(() => {
    if (tempUser !== null && tempUser !== undefined) {
      setPassword("");
    }
  }, [tempUser]);

  const handleSignOut = async () => {
    try {
      await Auth.signOut();
    } catch (error) {
      console.error("error signing out: ", error);
    }
  };

  return (
    <>
      <Grid container className={classes.root}>
        <Grid container item sm={6} className={classes.loginPanel}>
          <div className={classes.loginForm}>
            <div className={classes.logoAndTitles}>
              <img
                src={IrisCityLogo}
                className={classes.loginLogo}
                alt="irisCITY™"
              />
              <Typography variant="h5">City Patrol Portal</Typography>
              <Typography variant="caption">Version {pkg.version}</Typography>
            </div>
            <div className={classes.loginTextFieldsAndButton}>
              <TextField
                autoFocus
                value={username}
                margin="normal"
                placeholder={TRANSLATE_ONCE(LoginTranslate.USER_NAME.toString())}
                onChange={(event) => setUsername(event.target.value.trim())}
                InputProps={{
                  classes: {
                    input: classes.inputText,
                  },
                }}
              ></TextField>
              <TextField
                margin="normal"
                // label="Password"
                type="password"
                placeholder= {TRANSLATE_ONCE(LoginTranslate.PASSWORD.toString())}
                onChange={(event) => {
                  const {
                    target: { value },
                  } = event;
                  if (value.length > AWS_PASSWORD_MAX_LENGTH) return;
                  setPassword(value);
                }}
                value={password}
                InputProps={{
                  classes: {
                    input: classes.inputText,
                  },
                }}
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    handleLogin();
                  }
                }}
              ></TextField>

              <Button
                variant="contained"
                color="primary"
                endIcon={
                  loading && <i className="fa fa-circle-o-notch fa-spin" />
                }
                onClick={handleLogin}
                disabled={
                  !(
                    username.trim().length !== 0 &&
                    password.trim().length >= PASSWORD_MIN_LENGTH
                  ) || loading
                }
                className={classes.loginButton}
              >
                {TRANSLATE(LoginTranslate.LOGIN.toString())}
              </Button>
            </div>
            <div className={classes.resetPassword}>
              <Link
                to="/login/reset"
                className={classes.resetPasswordLink}
                onClick={handleSignOut}
              >
                {TRANSLATE(LoginTranslate.FORGET_PASSWORD.toString())}
              </Link>
            </div>
            <div className={classes.loginFormFooter}>
              <a
                href="https://www.irisradgroup.com/"
                target="_blank"
                rel="noreferrer"
              >
                {TRANSLATE(LoginTranslate.ABOUT_US.toString())}
              </a>
            </div>
          </div>
          {/* <div className = {classes.loginPanelFooter}>

          </div> */}
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            className={classes.loginFooter}
          >
            <Grid item md={4} sm={7}>
              <a href="https://www.esri.com" target="_blank" rel="noreferrer">
                <img
                  className={classes.cooperatorLogo}
                  src={esriLogo}
                  alt="esri home page"
                // style={{ width: "80%" }}
                />
              </a>
            </Grid>
            <Grid item md={4} sm={7}>
              <a
                href="https://www.geotab.com/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className={classes.cooperatorLogo}
                  src={geotabLogo}
                  alt="geotab home page"
                // style={{ maxWidth: "80%" }}
                />
              </a>
            </Grid>
          </Grid>
        </Grid>
        <Hidden xsDown>
          <Grid
            container
            item
            sm={6}
            alignItems="center"
            direction="column"
            className={classes.imagePanel}
          >
            <img src={irisLogo} className={classes.irisLogo} alt="IRIS" />
            <img
              src={welcomeImg}
              className={classes.welcomeImg}
              alt="Welcome to IrisGo Admin Console"
            />
          </Grid>
        </Hidden>
        <ResetPasswordModal
          open={tempUser !== undefined && tempUser !== null}
          tempCognitoUser={tempUser}
          onClose={resetTempUser}
          updateRestPasswordInfo={setResetPasswordInfo}
        />
      </Grid>
      <MuiSnackbar
        message={resetPasswordInfo.message}
        open={resetPasswordInfo.open}
        handleClose={() =>
          setResetPasswordInfo((prev) => ({ ...prev, open: false }))
        }
        severity={resetPasswordInfo.severity} // ["error", "info", "warning", "success"]
        position={resetPasswordInfo.position} // ["top-left", "top-center", "top-right", "bottom-left", "bottom-center", "bottom-right"]
      />
    </>
  );
}
export default withModal(Login);
