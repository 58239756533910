import HomeIcon from "@material-ui/icons/Home";
import AssessmentIcon from "@material-ui/icons/Assessment";
import CameraAltIcon from "@material-ui/icons/CameraAlt";

export const PROTECTED_ROUTES = {
  home: { name: "Home", path: "/", IconComponent: <HomeIcon /> },

  patrolForm: {
    name: "Manual Patrol",
    path: "/patrol-form",
    IconComponent: <CameraAltIcon />,
  },
  patrol: {
    name: "Patrol",
    path: "/patrol",
    IconComponent: <AssessmentIcon />,
  },
};
