import { IRISGO_BASE_URL } from "utils/env/urls";

const UPLOAD_IMAGE_URL = IRISGO_BASE_URL + "geo_info/";
const UPDATE_DATA_POINT_URL = IRISGO_BASE_URL + "datapoint/";
const UPLOAD_LABEL_URL = IRISGO_BASE_URL + "add_label/";
const LOOK_UP_URL = IRISGO_BASE_URL + "lookup/v1/";
const GET_ROUTE_URL = IRISGO_BASE_URL + "device/checkpoint/?device_sn=";

const TOGGLE_PATROL_URL = IRISGO_BASE_URL + "manualapp/";
const SEND_ROAD_CONDITION_URL = IRISGO_BASE_URL + "manualapp/road_condition/";

export {
  UPLOAD_IMAGE_URL,
  UPDATE_DATA_POINT_URL,
  UPLOAD_LABEL_URL,
  LOOK_UP_URL,
  TOGGLE_PATROL_URL,
  GET_ROUTE_URL,
  SEND_ROAD_CONDITION_URL,
};
