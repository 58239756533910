import styles from "./RoadAlerts.module.css";

import { MDSSContext } from "context/MDSSContext";
import { useContext, useEffect, useState } from "react";
import useAuth from "auth/hook/useAuth";

const RoadAlerts = () => {
  const {
    showRoadCondition,
    showRoadAlert,
    handleShowRoadCondition,
    MDSSData,
  } = useContext(MDSSContext);

  const {
    pageConfig: {
      patrolConfig: { additionalSetting: { mdss = true } = {} } = {},
    },
  } = useAuth();

  const [conditionColor, setConditionColor] = useState("default");

  useEffect(() => {
    const DataKV = [];
    //seperate by route id
    MDSSData.forEach((data) => {
      if (data["route_id"] === "DONVKV") {
        DataKV.push(data);
      }
    });

    if (DataKV.length !== 0) {
      const shownConditionColor = DataKV[0]["road_alert"].toUpperCase();
      setConditionColor(shownConditionColor);
    } else {
      setConditionColor("default");
    }
  }, [MDSSData]);

  return (
    <div>
      {showRoadAlert && mdss && (
        <div
          className={`${styles["road-alerts"]} ${styles[`${conditionColor}`]}`}
          onClick={() => {
            if (showRoadCondition) {
              handleShowRoadCondition(false);
            } else if (!showRoadCondition) {
              handleShowRoadCondition(true);
            }
          }}
        >
          <h5>ROAD ALERTS</h5>
          <div className={styles["road-alerts-ok"]}>OK</div>
          <div className={styles["road-alerts-marginal"]}>MARGINAL</div>
          <div className={styles["road-alerts-poor"]}>POOR</div>
          <div className={styles["road-alerts-extreme"]}>EXTREME</div>
        </div>
      )}
    </div>
  );
};

export default RoadAlerts;
