export const GET_YESTERDAY = new Date() - 3600000 * 24;

export const FILTER_CITY_LAYOUT = (
  preference,
  cityWidgets,
  totalWidgets,
  disabled_widgets
) => {
  let cityTotalWidget = {};
  let savedPreference = preference["iriscity_dashboard_layout"];

  //filter preference page widget list
  cityWidgets.forEach((widget) => {
    if (
      //if the disabled widget does not contain the current widget
      !disabled_widgets.some(
        (disabled_widget) => disabled_widget.key === widget.key
      )
    ) {
      // add the widget to the preference page's total widget list from totalWidget list
      cityTotalWidget[widget.key] = totalWidgets[widget.key];
    }
  });

  // filter preference
  // If profile has no layout preference use default
  if (
    savedPreference === undefined ||
    (Array.isArray(savedPreference) && savedPreference.length === 0)
  ) {
    savedPreference = [];
    Object.keys(cityTotalWidget).map((widgetKey) => {
      savedPreference.push({
        i: widgetKey,
        ...cityTotalWidget[widgetKey].layout,
      });
      return null;
    });
  } else {
    // If layout preference is found filter out disabled widgets
    disabled_widgets.map((w) => {
      savedPreference = savedPreference.filter((layout) => layout.i !== w.key);
      return null;
    });
  }
  preference["iriscity_dashboard_layout"] = savedPreference;
  return {
    defaultTotalLayout: cityTotalWidget,
    preferenceWithFilteredLayout: preference,
  };
};

export const PROCESS_MOBILE_USAGE_DATA = (usageData, deviceList) => {
  // for each damage type add related data to it
  let processedList = deviceList;
  for (const i in processedList) {
    processedList[i].usage = [];
    processedList[i].summary = { distance: 0, data: 0 };
    usageData.forEach((data) => {
      if (data.device_id === processedList[i].id) {
        processedList[i].usage = [...processedList[i].usage, data];
        processedList[i].summary.distance += data.distance;
        processedList[i].summary.data += data.data;
      }
    });
  }
  let filteredList = processedList.filter(
    (value) => value.summary.distance !== 0
  );

  let usageSummary = {
    id: 0,
    tag: "Summary",
    usage: [],
    summary: { distance: 0, data: 0 },
  };

  filteredList.forEach(function (device) {
    usageSummary.usage.push({
      name: device.tag,
      city: device.city.name,
      distance: device.summary.distance,
      data: device.summary.data,
    });
    usageSummary.summary.distance += device.summary.distance;
    usageSummary.summary.data += device.summary.data;
  });

  // combine and return results
  return [usageSummary, ...filteredList];
};

const SPECIAL_CHAR_REGEX = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>\/?~]/;

/**
 *
 * @param {string} inputValue
 * @param {string} replaceValue
 * @param {[string]} replaceValue default to  /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>\/?~]/
 */
const replaceSpecialChars = (
  inputValue,
  replaceValue,
  regex = SPECIAL_CHAR_REGEX
) => {
  let outputValue = inputValue;
  let match;
  do {
    match = regex.exec(outputValue);
    if (match) {
      const char = match[0];
      outputValue = outputValue.replace(char, replaceValue);
    }
  } while (match);

  return outputValue;
};

/**
 * remove special chars (but not hpyens) from a client's name
 * @param {string} value
 * @returns client name with consecutive spaces replaced by a singl space
 * 
 * @example 
 * 
 * const data = [
        ["Vaughan", "Vaughan"],
        ["SPLICE", "SPLICE"],
        ["TNS", "TNS"],
        ["EGIS", "EGIS"],
        ["CMTI", "CMTI"],
        ["Durham", "Durham"],
        ["Brantford", "Brantford"],
        ["NEXCO", "NEXCO"],
        ["Mota-Engil", "Mota-Engil"],
        ["ESRI-Italy", "ESRI-Italy"],
        ["Greeley", "Greeley"],
        ["Centennial", "Centennial"],
        ["Coppell", "Coppell"],
        ["Quinte_West", "Quinte West"],
        ["RanchoNexo", "RanchoNexo"],
        ["Saugeen_Shores", "Saugeen Shores"],
        ["Maple_Ridge", "Maple Ridge"],
        ["Applus+_Ventures", "Applus Ventures"],
    ];

    data.forEach((d) => {
        const [nameInDb, displayName] = d;
        const newName = remapClientName(nameInDb, "-");
        const isEqual = newName === displayName;
        if (isEqual) {
            console.log(
            `TRUE: [value, expectedValue]: [${nameInDb} converted to ${newName} === ${displayName}]`
            );
        } else {
            throw new Error(
            `FALSE: [value, expectedValue]: [${nameInDb} converted to ${newName} !== ${displayName}]`
            );
        }
    });
 */
export const remapClientName = (value) => {
  // regex without hypyen
  const sepRegex = /[`!@#$%^&*()_+=[\]{};':"\\|,.<>\/?~]/;
  const result = replaceSpecialChars(value, " ", sepRegex);
  // deal with consecutive spaces
  // https://stackoverflow.com/questions/31845255/javascript-get-index-of-first-special-regex-character-in-a-string
  return result.replace(/\s\s+/g, " ");
};
