import { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import ModalCloseButton from "../../irisUI/modalCloseButton/ModalCloseButton";
import UploadDataPointView from "./uploadDataPoint/UploadDataPointView";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  root: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    maxWidth: 800,
    width: "80%",
    height: "80%",
    // overflowY: "scroll",
    backgroundColor: "#fff",
  },

  mapView: {
    height: 400,
  },
}));

export default function UploadDataPointModal(props) {
  const loadingImageRef = useRef();
  const loadedIamgeRef = useRef();
  const { center, open: showModal, onClose: handleModalClose } = props;

  const [isLoading, setIsLoading] = useState(true);
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") return;
    if (typeof handleModalClose === "function") {
      handleModalClose();
    }
  };

  useEffect(() => {
    setOpen(showModal);
  }, [showModal]);

  useEffect(() => {
    const { current: loadedImgEle } = loadedIamgeRef;
    const { current: loadingImgEle } = loadingImageRef;
    if (loadedImgEle) {
      loadedImgEle.style.display = "none";
    }
    if (loadingImgEle) {
      loadingImgEle.style.display = "inline-block";
    }
  }, [loadedIamgeRef, loadingImageRef]);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.root}>
            <div
              style={{
                height: "70px",
                position: "relative",
                display: "flex",
                justifyContent: "flex-end",

                alignItems: "center",
              }}
            >
              <ModalCloseButton
                onClick={handleClose}
                style={{ marginRight: "1rem" }}
              />
            </div>
            <div style={{ flex: 1, overflowY: "scroll" }}>
              <UploadDataPointView
                position={props.position} // user's current location when this modal is opened
                onLocationUpdate={props.handleLocationUpdate} // callback to update user's refined location
                onUploadSuccess={props.onUploadSuccess} // callback to show upload success message
                onUploadFailed={props.onUploadFailed}
                handleClose={handleClose} // callback to close this modal
              />
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
