export class ManualPatrolFormTranslate {
    static ROOT_KEY = "MANUAL_PATROL_FORM"

    static SNOW_ACCUMULATION = new ManualPatrolFormTranslate('SNOW_ACCUMULATION');
    static TEMPERATURE = new ManualPatrolFormTranslate('TEMPERATURE');
    static VISIBILITY = new ManualPatrolFormTranslate('VISIBILITY');
    static WEATHER_CONDITION = new ManualPatrolFormTranslate('WEATHER_CONDITION');
    static ROUTES = new ManualPatrolFormTranslate('ROUTES');
    static DEVICE = new ManualPatrolFormTranslate('DEVICE');
    static SHIFT = new ManualPatrolFormTranslate('SHIFT');
    static ROAD_CONDITION = new ManualPatrolFormTranslate('ROAD_CONDITION');
    static RBD_BARE_DRY = new ManualPatrolFormTranslate('RBD_BARE_DRY');
    static RDW_DAMP_WET = new ManualPatrolFormTranslate('RDW_DAMP_WET');
    static RSP_ROAD_SNOW_PACKED = new ManualPatrolFormTranslate('RSP_ROAD_SNOW_PACKED');
    static RCB_ROAD_CENTRE_BARE = new ManualPatrolFormTranslate('RCB_ROAD_CENTRE_BARE');
    static RSC_ROAD_SNOW_CONVERAGE_BY_CM = new ManualPatrolFormTranslate('RSC_ROAD_SNOW_CONVERAGE_BY_CM');
    static RSL_ROAD_SLUSHY = new ManualPatrolFormTranslate('RSL_ROAD_SLUSHY');
    static RI_ROAD_ICY = new ManualPatrolFormTranslate('RI_ROAD_ICY');
    static RSD_ROAD_SNOW_DRIFTING = new ManualPatrolFormTranslate('RSD_ROAD_SNOW_DRIFTING');
    static WTB_WHEEL_TRACK_BARE = new ManualPatrolFormTranslate('WTB_WHEEL_TRACK_BARE');
    static START_PATROL = new ManualPatrolFormTranslate('START_PATROL');


    constructor(name) {
        this.name = name;
    }

    toString() {
        return ManualPatrolFormTranslate.ROOT_KEY + `.${this.name}`;
    }

}