/* eslint-disable default-case */
import TextField from "@material-ui/core/TextField";
import { Button } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import makeStyles from "@material-ui/core/styles/makeStyles";

import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { Auth } from "aws-amplify";

import IrisCityLogo from "../assets/IrisCityLogo.png";

/**3 different status for rendering*/
const PageSatus = {
  /**email input status*/
  step1: "email input",
  /**verification and reset password status*/
  step2: "verification and reset password",
  /**reset complete status*/
  step3: "reset complete",
};

/**css styles*/
const useStyles = makeStyles((theme) => ({
  resetComponent: {
    backgroundColor: "#7B7A7A55",
    top: 0,
    left: 0,
    width: "100vw",
    minHeight: "100vh",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  logoContainer: {
    marginTop: "2rem",
    display: "flex",
    justifyContent: "center",
  },
  resetLogo: {
    objectFit: "contain",
    width: "30%",
  },
  inputContainer: {
    marginTop: "2.5rem",
    width: "60vw",
    height: "15rem",
    borderRadius: "1rem",
    padding: "2rem",
    backgroundColor: "#ffffff",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  step1Error: {
    width: "50vw",
    margin: "2rem 0",
    fontSize: "1.4rem",
    color: "#c1151f",
    fontWeight: 700,
  },
  VCodeInputGuide: {
    width: "50vw",
    fontSize: "1.4rem",
    textAlign: "start",
  },
  VCodeNextButton: {
    border: "none",
    padding: "0.3rem 0.6rem",
    margin: "1rem 0 0 0",
    borderRadius: "3px",
    fontWeight: "bold",
    fontSize: "1rem",
    "&:focus": {
      outline: "none",
    },
    "&:enabled": {
      backgroundColor: "#c1151f",
      color: "#ffffff",
    },
    "&:hover": {
      cursor: "pointer",
      "&:enabled": {
        backgroundColor: "#A80006",
        // color: "#ffffff",
      },
    },
  },
  inputGuide: {
    width: "50vw",
    fontSize: "1.4rem",
    textAlign: "start",
  },
  inputText: {
    // margin: "0.375rem 0",
    width: "50vw",
    fontSize: "1.15rem",
    textAlign: "start",
  },
  buttons: {
    width: "50vw",
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "1rem",
  },
  buttonLink: {
    textDecoration: "none",
  },
  cancelButton: {
    border: "none",
    padding: "0.3rem 0.6rem",
    margin: "1rem 1.5rem 0 0",
    borderRadius: "3px",
    fontWeight: "bold",
    fontSize: "1rem",
    "&:focus": {
      outline: "none",
    },
    "&:hover": {
      cursor: "pointer",
    },
  },
  submitButton: {
    border: "none",
    padding: "0.3rem 0.6rem",
    margin: "1rem 0 0 0",
    borderRadius: "3px",
    fontWeight: "bold",
    fontSize: "1rem",
    "&:focus": {
      outline: "none",
    },
    "&:enabled": {
      backgroundColor: "#c1151f",
      color: "#ffffff",
    },
    "&:hover": {
      cursor: "pointer",
      "&:enabled": {
        backgroundColor: "#A80006",
        // color: "#ffffff",
      },
    },
  },
  newPasswordContainer: {
    marginTop: "2.5rem",
    width: "60vw",
    height: "31rem",
    borderRadius: "1rem",
    padding: "2rem",
    backgroundColor: "#ffffff",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  passwordInputfield: {
    width: "50vw",
    marginTop: 0,
    marginBottom: "2rem",
  },
  newPasswordInputGuide: {
    width: "50vw",
    fontSize: "1.2rem",
    textAlign: "start",
  },
  resetButton: {
    border: "none",
    padding: "0.3rem 0.6rem",
    margin: "1rem 0 0 0",
    borderRadius: "3px",
    fontWeight: "bold",
    fontSize: "1rem",
    "&:focus": {
      outline: "none",
    },
    "&:enabled": {
      backgroundColor: "#c1151f",
      color: "#ffffff",
    },
    "&:hover": {
      cursor: "pointer",
      "&:enabled": {
        backgroundColor: "#A80006",
        // color: "#ffffff",
      },
    },
  },
  step2Error: {
    width: "50vw",
    margin: "2rem 0",
    fontSize: "1.4rem",
    color: "#c1151f",
    fontWeight: 700,
  },
  completeContainer: {
    marginTop: "2.5rem",
    width: "60vw",
    height: "13rem",
    borderRadius: "1rem",
    padding: "2rem",
    backgroundColor: "#ffffff",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  completeInfo: {
    width: "50vw",
    fontSize: "1.4rem",
  },
  skipButton: {
    border: "none",
    padding: "0.3rem 0.6rem",
    marginTop: "1.8rem",
    borderRadius: "3px",
    fontWeight: "bold",
    fontSize: "1rem",
    "&:focus": {
      outline: "none",
    },
    "&:enabled": {
      backgroundColor: "#c1151f",
      color: "#ffffff",
    },
    "&:hover": {
      cursor: "pointer",
      "&:enabled": {
        backgroundColor: "#A80006",
        // color: "#ffffff",
      },
    },
  },
}));

// rules to check the new password
const containsSpecialChars = (string) => {
  return /[-!@#$%^&*()_+|~=`{}\[\]:";'<>?,.\/\\]/.test(string);
};
const containsOneLowerCase = (string) => {
  return /[a-z]/.test(string);
};
const containsOneUpperCase = (string) => {
  return /[A-Z]/.test(string);
};
const containsOneNumericChar = (string) => {
  return /[0-9]/.test(string);
};

const ResetPassword = () => {
  /**redirect variable*/
  const history = useHistory();
  /**css classes*/
  const classes = useStyles();

  //page status determine 3 major steps ui and user interaction
  const [pageStatus, setPageStatus] = useState(PageSatus.step1);

  //step1 user email input
  const [email, setEmail] = useState("");

  //step1 error message
  const [step1Error, setStep1Error] = useState(null);

  //step2 verification code input
  const [inputVCode, setInputVCode] = useState("");

  //step2 password input
  const [newPassword1, setNewPassword1] = useState("");
  const [newPassword2, setNewPassword2] = useState("");
  //step2 show password state
  const [showPassword1, setShowPassWord1] = useState(false);
  const [showPassword2, setShowPassWord2] = useState(false);

  //step2 error message
  const [step2Error, setStep2Error] = useState(null);

  //step2 rendered destination, obtained when step1 succeeds
  const [returnDes, setReturnDes] = useState(null);

  //step2 state to check if the new password meets the rules
  const [isPassword1OK, setIsPassword1OK] = useState(false);

  //step3 render time left to be redirected
  const [secondsToLoginPage, setSecondsToLoginPage] = useState(3);

  //step3 timeout switch
  const [timeoutToLoginOn, setTimeoutToLoginOn] = useState(false);

  /**step2 show password operator*/
  const handleClickShowPassword1 = () => {
    setShowPassWord1((prev) => !prev);
    setShowPassWord2((prev) => !prev);
  };
  // const handleClickShowPassword2 = () => {
  //   setShowPassWord2((prev) => !prev);
  // };

  /**step2 helper text for new password input*/
  const getHelperText = () => {
    const isTooShort = newPassword1.length < 8;
    const hasOneNumericChar = containsOneNumericChar(newPassword1);
    const hasOneUppercaseChar = containsOneUpperCase(newPassword1);
    const hasOneLowercaseChar = containsOneLowerCase(newPassword1);
    const hasOneSpecialChar = containsSpecialChars(newPassword1);

    return (
      <div>
        <span style={{ color: isTooShort ? "#c1151f" : "" }}>
          at least 8 characters
        </span>
        <br />
        <span style={{ color: hasOneNumericChar ? "" : "#c1151f" }}>
          must have 1 number
        </span>
        <br />
        <span style={{ color: hasOneUppercaseChar ? "" : "#c1151f" }}>
          must have 1 upper case
        </span>
        <br />
        <span style={{ color: hasOneLowercaseChar ? "" : "#c1151f" }}>
          must have 1 lower case
        </span>
        <br />
        <span style={{ color: hasOneSpecialChar ? "" : "#c1151f" }}>
          must have 1 special charater
        </span>
      </div>
    );
  };

  /**step1 submit the email to verify the user*/
  const handleSubmit = () => {
    Auth.forgotPassword(email)
      .then((data) => {
        setReturnDes(data.CodeDeliveryDetails.Destination);
        setPageStatus(PageSatus.step2);
        setInputVCode("");
        setStep2Error(null);
      })
      .catch((err) => {
        console.error(err);
        console.error(err.name);
        switch (err.name) {
          case "LimitExceededException":
            setStep1Error(
              "Attempt limit exceeded, please try after some time."
            );
            setEmail("");
            break;
          case "UserNotFoundException":
            setStep1Error("Email not found, please try with another one.");
            setEmail("");
            break;
          case "CodeDeliveryFailureException":
            setStep1Error(
              "Verification code fails to deliver, please try one more time."
            );
            setEmail("");
            break;
          default:
            setEmail("");
        }
      });
  };

  /**step2 finishes, goes to step3 and starts timer on step3*/
  const handleReset = () => {
    // aws method to set new password
    Auth.forgotPasswordSubmit(email, inputVCode, newPassword1)
      .then((data) => {
        // ui goes to next step
        setPageStatus(PageSatus.step3);
        //step3 timer starts
        setTimeoutToLoginOn(true);
      })
      .catch((err) => {
        window.scrollTo(0, 0);
        console.error(err);
        console.error(Object.keys(err));
        console.error(err.name, err.code);
        if (err.name === "CodeMismatchException") {
          setStep2Error(
            "Invalid verification code provided, please try again."
          );
        } else if (err.name === "LimitExceededException") {
          setStep2Error("Attempt limit exceeded, please try after some time.");
        }
      });
  };

  /**step3 skip waiting*/
  const handleSkipWaiting = () => {
    // setTimeoutToLoginOn(false);
    history.push("/login");
  };

  //step3 set timeout and set interval handler with useEffect
  useEffect(() => {
    let timeoutToLogin = null;
    let intervalToLogin = null;

    //timer starts
    if (timeoutToLoginOn) {
      intervalToLogin = setInterval(() => {
        setSecondsToLoginPage((prev) => prev - 1);
      }, 1000);
      timeoutToLogin = setTimeout(() => {
        history.push("/login");
      }, 3 * 1000);
    }
    return () => {
      clearTimeout(timeoutToLogin);
      clearInterval(intervalToLogin);
    };
  }, [timeoutToLoginOn]);

  /**rendered content depending on different status*/
  const renderContent = () => {
    if (pageStatus === PageSatus.step1) {
      return (
        <div
          className={classes.inputContainer}
          style={{ height: step1Error ? "24rem" : "15rem" }}
        >
          {step1Error && <p className={classes.step1Error}>{step1Error}</p>}
          <p className={classes.inputGuide}>
            Please enter your email to search for your account:
          </p>
          <TextField
            autoFocus
            type="email"
            value={email}
            margin="normal"
            placeholder="email"
            onChange={(event) => setEmail(event.target.value.trim())}
            InputProps={{
              classes: {
                input: classes.inputText,
              },
            }}
          ></TextField>
          <div className={classes.buttons}>
            <Link to="/login" className={classes.buttonLink}>
              <Button variant="contained" className={classes.cancelButton}>
                Cancel
              </Button>
            </Link>
            <Button
              variant="contained"
              onClick={handleSubmit}
              className={classes.submitButton}
              disabled={email === ""}
            >
              Submit
            </Button>
          </div>
        </div>
      );
    } else if (pageStatus === PageSatus.step2) {
      return (
        <div
          className={classes.newPasswordContainer}
          style={{ height: step2Error ? "48rem" : "42rem" }}
        >
          {step2Error && <div className={classes.step2Error}>{step2Error}</div>}
          <p
            className={classes.newPasswordInputGuide}
          >{`A verification code has been sent to ${returnDes}.`}</p>
          <p className={classes.newPasswordInputGuide}>
            "Please enter verification code:"
          </p>
          <TextField
            autoFocus
            value={inputVCode}
            placeholder="verification code"
            helperText="6 digits number"
            type="text"
            onInput={(event) =>
              (event.target.value = event.target.value
                .replace(/[^0-9.]/g, "")
                .replace(/(\..*)\./g, "$1"))
            }
            onChange={(event) => setInputVCode(event.target.value.trim())}
            inputProps={{ maxLength: 6 }}
            InputProps={{
              classes: {
                input: classes.inputText,
              },
            }}
          ></TextField>
          <p className={classes.newPasswordInputGuide}>
            Please enter new password:
          </p>
          <FormControl>
            <Input
              disabled={inputVCode.length !== 6}
              style={{ marginBottom: 0 }}
              className={classes.passwordInputfield}
              autoFocus
              value={newPassword1}
              type={showPassword1 ? "text" : "password"}
              margin="normal"
              placeholder="password"
              // helperText={getHelperText()}
              onChange={(event) => {
                const inputValue = event.target.value.trim();
                setNewPassword1(inputValue);
                setIsPassword1OK(
                  containsSpecialChars(inputValue) &&
                    containsOneLowerCase(inputValue) &&
                    containsOneUpperCase(inputValue) &&
                    containsOneNumericChar(inputValue) &&
                    inputValue.length > 7
                );
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword1}
                    // onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword1 ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              InputProps={{
                classes: {
                  input: classes.inputText,
                },
              }}
            />
            <FormHelperText>{getHelperText()}</FormHelperText>
          </FormControl>
          <p className={classes.newPasswordInputGuide}>
            Please reenter new password:
          </p>
          <Input
            className={classes.passwordInputfield}
            value={newPassword2}
            type={showPassword2 ? "text" : "password"}
            margin="normal"
            placeholder="password"
            onChange={(event) => setNewPassword2(event.target.value.trim())}
            disabled={!isPassword1OK || inputVCode.length !== 6}
            // endAdornment={
            //   <InputAdornment position="end">
            //     <IconButton
            //       aria-label="toggle password visibility"
            //       onClick={handleClickShowPassword2}
            //       // onMouseDown={handleMouseDownPassword}
            //     >
            //       {showPassword2 ? <Visibility /> : <VisibilityOff />}
            //     </IconButton>
            //   </InputAdornment>
            // }
            InputProps={{
              classes: {
                input: classes.inputText,
              },
            }}
          />
          <div className={classes.buttons}>
            <Button
              variant="contained"
              className={classes.cancelButton}
              onClick={() => {
                setStep1Error(null);
                setPageStatus(PageSatus.step1);
              }}
            >
              Back
            </Button>
            <Button
              variant="contained"
              onClick={handleReset}
              className={classes.resetButton}
              disabled={
                newPassword1 !== newPassword2 ||
                newPassword1 === "" ||
                newPassword2 === "" ||
                inputVCode.length !== 6
              }
            >
              Reset password
            </Button>
          </div>
        </div>
      );
    } else if (pageStatus === PageSatus.step3) {
      return (
        <div className={classes.completeContainer}>
          <p className={classes.completeInfo}>
            Password is reset successfully. Please wait for {secondsToLoginPage}{" "}
            seconds to be redirected to login page
          </p>
          <Button
            variant="contained"
            onClick={handleSkipWaiting}
            className={classes.skipButton}
          >
            Skip
          </Button>
        </div>
      );
    }
  };

  return (
    <div className={classes.resetComponent}>
      <div className={classes.logoContainer}>
        <img src={IrisCityLogo} className={classes.resetLogo} alt="irisCITY™" />
        {/* <Typography variant="h5">City Patrol Portal</Typography>
        <Typography variant="caption">Version {pkg.version}</Typography> */}
      </div>
      <div>{renderContent()}</div>
    </div>
  );
};

export default ResetPassword;
