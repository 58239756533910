import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import * as MANUAL_PATROL_FORM from "../pages/manualPatrol/ManualPatrolForm.translation.json"
import * as LOGIN from "../components/Login.translation.json"
import * as COMMON from "../components/language/common.translation.json"
export const SUPPORTED_LANG = [
  { label: "EN", value: "en" },
  { label: "FR", value: "fr" },
];

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18ne xt-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: "en",
    supportedLngs: SUPPORTED_LANG.map((lng) => lng.value),
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },

    resources: {
      en: {
        translation: {
          COMMON: COMMON.en,
          Good_Morning: "Good morning",
          MANUAL_PATROL_FORM: MANUAL_PATROL_FORM.en,
          LOGIN:LOGIN.en
        },
      },
      fr: {
        translation: {
          COMMON: COMMON.fr,
          MANUAL_PATROL_FORM: MANUAL_PATROL_FORM.fr,
          LOGIN:LOGIN.fr
        },
      },
    },

    // for language dector https://github.com/i18next/i18next-browser-languageDetector
    detection: {
      order: ["cookie", "localStorage", "path", "subdomain"],
      caches: ["cookie"],
    },
  });

export default i18n;
