import { createContext, useState } from "react";

export const AlertContext = createContext([
  {
    /** alert message */
    message: "",
    /** true if custom modal should be shown, false otherwise */
    isOpen: false,
    /** function to close openend custom modal */
    dismiss: null,
  },
  () => {
    // some function
  },
]);

const AlertContextProvider = ({ children }) => {
  const [alert, setAlert] = useState({
    message: "",
    isOpen: false,
    dismiss: null,
  });

  return (
    <AlertContext.Provider value={[alert, setAlert]}>
      {children}
    </AlertContext.Provider>
  );
};

export default AlertContextProvider;
