import i18next from "../../config/i18n";
import { Translation } from "react-i18next";

/**
 * Sends back a component that will listen to languge changes
 * @param {*} key The key that matches the *.translate.json
 * @param {*} txt Fall back text if key is missing.
 * @returns
 */
export const TRANSLATE = (key, txt = "") => {
  return (
    <Translation>
      {(t, { i18n }) => {
        return key && i18n.exists(key) ? t(key) : txt;
      }}
    </Translation>
  );
};

export const CREATE_LANG_KEY = (str, rootKey = null, ns = null,) => {
  let key = str
    .trim()
    .replace(/[\W_]+/g, "_")
    .replace(/^_+/, '')
    .replace(/_+$/, '')
    .toUpperCase();

  // incase a key is started by a number, ie: 911_SOME_KEY,
  // convert it as SOME_KEY_911 instead as cannot set up key
  // which starts with a number
  const regex = new RegExp(/[0-9]/);
  if (regex.test(key.charAt(0))) {
    const elements = key.split("_");
    let target = elements[0];
    let count = elements.length;
    while (elements.length > 0 && regex.test(target)) {
      const temp = elements.shift();
      elements.push(temp);
      target = elements[0];
      count--;
    }
    key = elements.join("_");
  }
  let finalKey = ""
  if (rootKey) {
    finalKey += rootKey + ':'
  }
  if (ns) {
    finalKey += ns + "."
  }
  finalKey += key
  return finalKey;
};


/**
 *
 * get translation for common strings, ie: header title, button text, etc
 * @param {string} key
 * @returns
 */
export const getCommonTranslation = (key) => {
  const k = CREATE_LANG_KEY(key);
  // return t("common:" + k);
  return i18next.t("common:" + k);
};

/**
 * This call is not binded to component changes
 * @param {*} key The key that matches the *.translate.json
 * @param {*} txt Fall back text if key is missing.
 */
export const TRANSLATE_ONCE = (key, txt = "") =>
  key && i18next.exists(key) ? i18next.t(key) : txt;
