import React, { useState } from "react";
export const AuthContext = React.createContext({
  deviceInfo: { id: -1, idV2: -1, device_sn: "", tag: "" },
});

export const AuthContextProvider = ({ children }) => {
  const [contextUsername, setContextUsername] = useState();
  const [contextToken, setContextToken] = useState();

  const [deviceInfo, setDeviceInfo] = useState(null);
  const [labels, setLabels] = useState([]);

  // use on the feature layer for filtering features
  const [filterExpression, setFilterExpression] = useState("1=1"); // default expression indicate to show all features on the map

  const contextValue = {
    contextUsername, // okay
    contextToken,
    deviceInfo,
    labels,
    filterExpression,

    setContextUsername,
    setContextToken,
    setDeviceInfo,
    setLabels,
    setFilterExpression,
  };

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};
