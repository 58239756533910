// Set to Production Server
// dummy to trigger redeployment in Amplify
const IRISGO_BASE_URL = new URL("https://post.api.irisradgroup.com/api/");

// Set to Test Server
// const IRISGO_BASE_URL = new URL("https://irisgo.api.irisradgroup.com/api/");

// const IRIS_DATA_URL = "http://ec2-3-98-27-71.ca-central-1.compute.amazonaws.com:8081"; // test
const IRIS_DATA_URL = "https://data.irisradgroup.com"; // prod
const IRIS_WORK_ORDER_END_POINT = `${IRIS_DATA_URL}/api/workorder`;

const IRISGO_LOGIN_URL = `${IRISGO_BASE_URL}token/`;
const IRISGO_LOOKUP_URL = `${IRISGO_BASE_URL}lookup/v1/`;
const IRISGO_DEVICE_UPDATE_URL = `${IRISGO_BASE_URL}device/`;
const IRISGO_USER_PROFILE_UPDATE_URL = `${IRISGO_BASE_URL}profile/`;
const IRISGO_FETCH_URL = `${IRISGO_BASE_URL}datapoint/`;
const IRISGO_DISTANCE_URL = `${IRISGO_BASE_URL}report/distance/`;
const IRISGO_LABELER_URL = `${IRISGO_BASE_URL}report/view/`;
const IRISGO_UPLOAD_URL = `${IRISGO_BASE_URL}geo_info/`;
const IRISGO_UPDATE_URL = `${IRISGO_BASE_URL}datapoint`;
const IRISGO_DEVICE_USAGE_URL = `${IRISGO_BASE_URL}report/device/`;
const IRISGO_NEW_DEVICE_URL = `${IRISGO_BASE_URL}device/new/`;
const IRISGO_DOWNLOAD_CSV_URL = `${IRISGO_BASE_URL}download_csv`;
const IRISGO_CREATE_NEW_USER_URL = `${IRISGO_BASE_URL}create_user/`;
const IRISGO_RESET_USER_PASSWORD = `${IRISGO_BASE_URL}change_password/`;
const IRISGO_LABEL_URL = `${IRISGO_BASE_URL}label/`;

// Sentry API Gateway
const SENTRY_GATEWAY_URL = new URL("https://sentry.api.irisradgroup.com/");
const SENTRY_GATEWAY_LOOKUP_URL = `${SENTRY_GATEWAY_URL}lookup`;

// Hexnode API Base
const HEXNODE_URL = new URL("https://irisradgroup.hexnodemdm.com/api/v1/");
const HEXNODE_UPDATE_DEVICE_NAME_URL = `${HEXNODE_URL}actions/save_friendly_name/`;
const HEXNODE_LOOKUP_URL = `${HEXNODE_URL}devices/`;

// Open Weather API
const OPEN_WEATHER_URL = new URL("https://api.openweathermap.org/data/2.5/");
const OPEN_WEATHER_CURRENT_URL = `${OPEN_WEATHER_URL}weather`;

// GIS Map

const GIS_MAP_URL = new URL("https://portal.irisradgroup.com");

const REBOOT_DEVICE_URL = `${IRISGO_BASE_URL}device/reboot/`;

export const REQUEST_NEW_ACCOUNT_URL =
  "https://irisgo.api.irisradgroup.com/api/send_contact/";

// create work order entry url
const CREATE_WORK_ORDER_URL =
  "https://9wm4qo7u9j.execute-api.ca-central-1.amazonaws.com/alpha?table=work_order&batch=False";
// const CREATE_WORK_ORDER_URL =
//   "https://9wm4qo7u9j.execute-api.ca-central-1.amazonaws.com/alpha?table=TestWorkOrderSep22&batch=False";

const CREATE_WORK_ORDER_NEW_URL = `${IRIS_DATA_URL}/api/workorder/create/`;
const CREATE_DEFECT_URL = `${IRIS_DATA_URL}/api/defects/`;
const LIST_DEFECT_TYPE_URL = `${IRIS_DATA_URL}/api/defect/`;

const IRIS_AUTH_ROOT_URL = "https://auth.api.irisradgroup.com"; // production
// const IRIS_AUTH_ROOT_URL =
// "http://ec2-3-98-27-71.ca-central-1.compute.amazonaws.com:80"; // test

const IRIS_VERITY_USER_URL = `${IRIS_AUTH_ROOT_URL}/verify/`;

const IRIS_DEVICE_ROOT_URL = "https://device.api.irisradgroup.com";
// const IRIS_DEVICE_ROOT_URL = "http://ec2-3-98-27-71.ca-central-1.compute.amazonaws.com:8080";
const IRIS_DEVICE_CLIENT_URL = `${IRIS_DEVICE_ROOT_URL}/device/list/`;
const DEVICE_STATUS_URL = `${IRIS_DEVICE_ROOT_URL}/device/status/`;

export {
  GIS_MAP_URL,
  IRISGO_BASE_URL,
  IRISGO_DEVICE_UPDATE_URL,
  IRISGO_USER_PROFILE_UPDATE_URL,
  IRISGO_LOGIN_URL,
  IRISGO_LOOKUP_URL,
  IRISGO_FETCH_URL,
  IRISGO_DISTANCE_URL,
  IRISGO_LABELER_URL,
  IRISGO_UPLOAD_URL,
  IRISGO_UPDATE_URL,
  IRISGO_DEVICE_USAGE_URL,
  SENTRY_GATEWAY_LOOKUP_URL,
  HEXNODE_UPDATE_DEVICE_NAME_URL,
  HEXNODE_LOOKUP_URL,
  IRISGO_NEW_DEVICE_URL,
  OPEN_WEATHER_CURRENT_URL,
  IRISGO_DOWNLOAD_CSV_URL,
  IRISGO_CREATE_NEW_USER_URL,
  IRISGO_RESET_USER_PASSWORD,
  IRISGO_LABEL_URL,
  CREATE_WORK_ORDER_URL,
  DEVICE_STATUS_URL,
  REBOOT_DEVICE_URL,
  IRIS_WORK_ORDER_END_POINT,
  IRIS_VERITY_USER_URL,
  IRIS_DEVICE_CLIENT_URL,
  CREATE_WORK_ORDER_NEW_URL,
  CREATE_DEFECT_URL,
  LIST_DEFECT_TYPE_URL,
};
