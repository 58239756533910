import { useCallback, useContext, useEffect, useState } from "react";
import { AlertContext } from "../context/AlertContextProvider";

const useAlert = () => {
  const [alert, setAlert] = useContext(AlertContext);
  const [needsCleanup, setNeedsCleanup] = useState(false);

  /**
   * @summary set up alert message on the custom alert modal
   *
   * @param {string} message alert message to be shown on the custom alert modal
   * @returns A promise, resolve or not, the modal would be dismissed
   */
  const showAlert = useCallback(
    (message) => {
      const promise = new Promise((resolve, reject) => {
        setAlert({ message, isOpen: true, dismiss: resolve });
        setNeedsCleanup(true);
      });

      const reset = () => {
        setAlert({ message: "", dismiss: null, isOpen: false });
        setNeedsCleanup(false);
      };

      return promise.then(
        () => {
          reset();
          return true;
        },
        () => {
          reset();
          return false;
        }
      );
    },
    [setAlert]
  );

  // Call cancel in a cleanup func to avoid dangling confirm dialog
  useEffect(() => {
    return () => {
      if (alert.dismiss && needsCleanup) {
        alert.dismiss();
      }
    };
  }, [alert, needsCleanup]);

  return {
    ...alert,
    showAlert,
  };
};

export default useAlert;
