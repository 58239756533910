export class LoginTranslate {
    static ROOT_KEY = "LOGIN"

    static USER_NAME = new LoginTranslate('USER_NAME');
    static PASSWORD = new LoginTranslate('PASSWORD');
    static LOGIN = new LoginTranslate('LOGIN');
    static FORGET_PASSWORD = new LoginTranslate('FORGET_PASSWORD');
    static ABOUT_US = new LoginTranslate('ABOUT_US');


    constructor(name) {
        this.name = name;
    }

    toString() {
        return LoginTranslate.ROOT_KEY + `.${this.name}`;
    }

}