import {
  DEVICE_STATUS_URL,
  IRISGO_BASE_URL,
  REBOOT_DEVICE_URL,
} from "utils/env/urls";
import { TOGGLE_PATROL_URL, SEND_ROAD_CONDITION_URL } from "./apiURLS";
import { getAwsToken } from "auth/hook/useAuth";

/**
 * @description mark device as in patrol or finish patrol on the server
 *
 * @param {string} token access token
 * @param {object} params param object
 * @param {boolean} inPatrol ture means start patrol, otherwise, false
 * @returns {Promise}
 */
export const togglePatrolRequest = async (token, params, inPatrol) => {
  const {
    patroler_name = "",
    device_sn = "",
    shift = "",
    weather = "",
    patrol_type = "",
    confirm_road_condition = undefined,
    routes_selection = "",
  } = params;

  const formData = new FormData();
  formData.append("start_time", new Date().toJSON());
  formData.append("patroler_name", patroler_name);
  formData.append("device_sn", device_sn);
  formData.append("shift", shift);
  formData.append("weather", weather);
  formData.append("in_patrol", inPatrol + "");
  formData.append("patrol_type", patrol_type);
  formData.append("patrol_route", routes_selection);

  if (confirm_road_condition !== undefined) {
    formData.append("confirm", confirm_road_condition);
  }

  const options = {
    method: "POST",
    headers: {
      Authorization: `${token}`,
    },
    body: formData,
  };
  return new Promise(async (resolve, reject) => {
    const response = await fetch(TOGGLE_PATROL_URL, options);
    let error;
    if (response.ok) {
      const responseJson = await response.json();
      resolve(responseJson);
    } else {
      const responseJson = await response.json();
      const { message } = responseJson;

      const { status } = response;
      if (status === 401 || status === 403) {
        error = new Error(`Error: ${status}, Access Denied, ${message}`);
      } else if (status === 404) {
        error = new Error(`Error: ${status}, Page Not Found, ${message}`);
      } else if (status >= 500) {
        error = new Error(
          `Error: ${status}: Internal Server Error, ${message}`
        );
      } else {
        error = new Error(`general_error_message, ${message}`);
      }
      reject(error);
    }
  });
};
/**
 * @description send road condition to server
 *
 * @param {string} accessToken access token
 * @param {string} device_sn device serial number
 * @param {string} roadCondition road condition name
 * @returns {Promise}
 */
export const sendRoadCondition = async (
  accessToken,
  device_sn,
  roadCondition
) => {
  const body = JSON.stringify({
    device_sn: device_sn,
    timestamp: new Date().toJSON(),
    road_condition: roadCondition,
  });
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${accessToken}`,
    },
    body: body,
  };
  return new Promise(async (resolve, reject) => {
    const response = await fetch(SEND_ROAD_CONDITION_URL, options);
    let error;
    if (response.ok) {
      const responseJson = await response.json();
      resolve(responseJson);
    } else {
      const { status } = response;
      const body = await response.json();
      const errorMessage = `Error ${status}: ${body.message}`;
      if (status === 401 || status === 403) {
        error = new Error(errorMessage);
      } else if (status === 404) {
        error = new Error(errorMessage);
      } else if (status >= 500) {
        error = new Error(errorMessage);
      } else {
        error = new Error(errorMessage);
      }
      reject(error);
    }
  });
};

/**
 * @description send request to reboot a device
 * @param {number} id device id
 * @returns A promise, on resolve, device should be rebooting, if it is on the grid of Hexnode, false otherwise
 */
export const getDeviceStatus = async (id) => {
  const url = new URL(DEVICE_STATUS_URL);
  url.searchParams.append("id", id);
  return new Promise(async (resolve, reject) => {
    const token = await getAwsToken();

    const options = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch(url.toString(), options);
    let error;
    if (response.ok) {
      const responseJson = await response.json();
      resolve(responseJson);
    } else {
      const { status } = response;
      const message = await response.text();
      const errorMessage = `Error ${status}: ${message}`;
      if (status === 401 || status === 403) {
        error = new Error(errorMessage);
      } else if (status === 404) {
        error = new Error(errorMessage);
      } else if (status >= 500) {
        error = new Error(errorMessage);
      } else {
        error = new Error(errorMessage);
      }
      reject(error);
    }
  });
};

/**
 *
 * @param {string} fromTime local time string, in **"MM/dd/yyyy HH:00:00"** format
 * @param {string} toTime local time string, in **"MM/dd/yyyy HH:00:00"** format
 * @param {string} [nextToken] graphQL pagination token
 * @returns {Promise<{customizedData: [Object], nextToken:string | null}>}
 */
export const fetchMDSS = async (fromTime, toTime, nextToken = "") => {
  var myHeaders = new Headers();
  myHeaders.append("X-Api-Key", "da2-x6whyvms3jegnp6etwazqufuqu");
  myHeaders.append("Content-Type", "application/json");

  var graphql = JSON.stringify({
    query: `query MyQuery {\n  listMDSSES(filter: {time: {between: ["${fromTime}", "${toTime}"]}}, limit: 10000, nextToken: "${nextToken}") {\n    items {\n      air_temperature\n      available_chemicals\n      blowing_snow_alert\n      dewpoint\n      freezing_rate\n      mobility_index\n      plow\n      rain_rate\n      rh\n      road_alert\n      road_temperature\n      route_id\n      snow_on_road\n      snow_rate\n      time\n      treatment\n      weather_alert\n      wind_alert\n      wind_direction\n      wind_speed\n    }\n    nextToken\n  }\n}`,
    variables: {},
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: graphql,
    redirect: "follow",
  };

  return new Promise(async (resolve, reject) => {
    const response = await fetch(
      "https://u3r4hnowynft5omcvjbv7ssu74.appsync-api.ca-central-1.amazonaws.com/graphql",
      requestOptions
    );
    let error;
    if (response.ok) {
      const fetchedDataString = await response.text();

      const fetchedData = JSON.parse(fetchedDataString);

      //customize data by adding required properties
      const nextToken = fetchedData?.data?.listMDSSES?.nextToken;
      const customizedData =
        JSON.parse(fetchedDataString)?.data?.listMDSSES?.items || [];
      customizedData.forEach((oneData) => {
        oneData["Air Temperature (°C)"] = oneData["air_temperature"];
        oneData["Road Temperature (°C)"] = oneData["road_temperature"];
        oneData["Snow on Road (cm)"] = oneData["snow_on_road"];
      });

      //add precipitation property to each data object
      customizedData.forEach((oneData) => {
        const sortableKV = [
          ["FREEZING", Number(oneData["freezing_rate"])],
          ["RAIN", Number(oneData["rain_rate"])],
          ["SNOW", Number(oneData["snow_rate"])],
        ];
        sortableKV.sort((a, b) => b[1] - a[1]);
        // oneData["Precipitation Rate"] = sortableKV[0][1].toString();
        if (sortableKV[0][1] === 0) {
          oneData["precipitation"] = {
            precipitation_type: "None",
            rate: sortableKV[0][1].toString(),
          };
          // oneData["precipitation_type"] = "None";
        } else {
          oneData["precipitation"] = {
            precipitation_type: sortableKV[0][0],
            rate: sortableKV[0][1].toString(),
          };
          // oneData["precipitation_type"] = sortableKV[0][0];
        }
        // oneData["Rate"] = sortableKV[0][1].toString();
      });

      resolve({ customizedData, nextToken });
    } else {
      const { status } = response;
      const body = await response.json();
      const errorMessage = `Error ${status}: ${body.message}`;
      if (status === 401 || status === 403) {
        error = new Error(errorMessage);
      } else if (status === 404) {
        error = new Error(errorMessage);
      } else if (status >= 500) {
        error = new Error(errorMessage);
      } else {
        error = new Error(errorMessage);
      }
      reject(error);
    }
  });
};

/**
 *
 * @description due to there is pagination setup, by specifying **nextToken** in graphQl
 *
 * if its value is not null, it means not **ALL** values that matched the given query are returned.
 *
 * As a result, we would better to send the same query with valid token in order to have all values returned
 *
 *
 * @param {string} fromTime local time string, in **"MM/dd/yyyy HH:00:00"** format
 * @param {string} toTime local time string, in **"MM/dd/yyyy HH:00:00"** format
 * @returns {Promise<[object]>}
 */
export const fetchAllMDSS = async (fromTime, toTime) => {
  let nextToken = "";
  let allMDSSData = [];

  do {
    try {
      const { customizedData, nextToken: token } = await fetchMDSS(
        fromTime,
        toTime,
        nextToken
      );
      nextToken = token;
      allMDSSData = allMDSSData.concat(customizedData);
    } catch (error) {
      return Promise.reject(error);
    }
  } while (nextToken !== null);

  return Promise.resolve(allMDSSData);
};
