import {
  CREATE_WORK_ORDER_URL,
  CREATE_WORK_ORDER_NEW_URL,
  LIST_DEFECT_TYPE_URL,
  CREATE_DEFECT_URL,
} from "utils/env/urls";
import DateUtils from "../dateUtils/DateUtils";

import {
  UPLOAD_IMAGE_URL,
  UPDATE_DATA_POINT_URL,
  UPLOAD_LABEL_URL,
  LOOK_UP_URL,
  GET_ROUTE_URL,
} from "./apiURLS";

// device_sn: 4
// latitude: 45.49767748
// longitude: -73.65223727

export const getHardCodedImageMetadata = (deviceId, lat, lng, imageFile) => {
  const ts = DateUtils.getDatabaseTimeStamp();
  return {
    device_sn: deviceId,
    latitude: lat,
    longitude: lng,
    "accelerometer_info[0]acc_x": 0.0,
    "accelerometer_info[0]acc_y": 0.0,
    "accelerometer_info[0]acc_z": 0.0,
    "accelerometer_info[0]linear_acc_z": 0.0,
    "accelerometer_info[0]linear_acc_y": 0.0,
    "accelerometer_info[0]linear_acc_x": 0.0,
    app_version: 1,
    speed: 0.0,
    heading: 0,
    "accelerometer_info[0]timestamp": ts,
    create_time: ts,
    image: imageFile,
  };
};

export const uploadImageRequest = async (token, data) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: `${token}`,
    },
    body: getFormData(data),
  };
  return new Promise(async (resolve, reject) => {
    const response = await fetch(UPLOAD_IMAGE_URL, options);
    let error;

    if (response.ok) {
      const responseJson = await response.json();
      resolve(responseJson);
    } else {
      if (response.status === 401) {
        error = new Error("access_denied__error_message");
      } else if (response.status === 404) {
        error = new Error("page_not_found_error_message");
      } else if (response.status >= 500) {
        error = new Error("server_error_message");
      } else {
        error = new Error("general_error_message");
      }
      reject(error);
    }
  });
};

export const createBoundingBoxesRequest = async (token, boxes, dataPointId) => {
  const boundingBoxObject = getBoudingBoxesUploadData([boxes], dataPointId);

  const options = {
    method: "POST",
    headers: {
      Authorization: `${token}`,
    },
    body: getFormData(boundingBoxObject),
  };
  return new Promise(async (resolve, reject) => {
    const response = await fetch(UPLOAD_LABEL_URL, options);
    let error;

    if (response.ok) {
      const responseJson = await response.json();
      if (responseJson) {
        responseJson.label_type = boxes.id;
        responseJson.w_o_type = boxes.name;
      }

      resolve(responseJson);
    } else {
      if (response.status === 401) {
        error = new Error("access_denied__error_message");
      } else if (response.status === 404) {
        error = new Error("page_not_found_error_message");
      } else if (response.status >= 500) {
        error = new Error("server_error_message");
      } else {
        error = new Error("general_error_message");
      }
      reject(error);
    }
  });
};

export const updateDataPointRequest = async (
  token,
  dataPointId,
  comment = ""
) => {
  const formData = new FormData();
  formData.append("comment", comment);

  const options = {
    method: "POST",
    headers: {
      Authorization: `${token}`,
    },
    body: formData,
  };
  return new Promise(async (resolve, reject) => {
    const response = await fetch(
      UPDATE_DATA_POINT_URL + dataPointId + "/",
      options
    );
    let error;
    if (response.ok) {
      const responseJson = await response.json();
      resolve(responseJson);
    } else {
      if (response.status === 401) {
        error = new Error("access_denied__error_message");
      } else if (response.status === 404) {
        error = new Error("page_not_found_error_message");
      } else if (response.status >= 500) {
        error = new Error("server_error_message");
      } else {
        error = new Error("general_error_message");
      }
      reject(error);
    }
  });
};

export const getCurrentRoute = async (token, device_sn, isAll) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: `${token}`,
    },
  };

  let url = GET_ROUTE_URL + device_sn;
  if (isAll === true || isAll === "true") {
    url += "&all=true";
  }
  return new Promise(async (resolve, reject) => {
    const response = await fetch(url, options);
    let error;
    if (response.ok) {
      const responseJson = await response.json();
      resolve(responseJson);
    } else {
      if (response.status === 401) {
        error = new Error("access_denied__error_message");
      } else if (response.status === 404) {
        error = new Error("page_not_found_error_message");
      } else if (response.status >= 500) {
        error = new Error("server_error_message");
      } else {
        error = new Error("general_error_message");
      }
      reject(error);
    }
  });
};

export const getLabelCityData = async (token) => {
  const url = new URL(LOOK_UP_URL);
  url.searchParams.append("city", true);
  url.searchParams.append("label_type", true);
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
  };
  return new Promise(async (resolve, reject) => {
    const response = await fetch(url.toString(), options);
    let error;
    if (response.ok) {
      const responseJson = await response.json();
      resolve(responseJson);
    } else {
      if (response.status === 401) {
        error = new Error("access_denied__error_message");
      } else if (response.status === 404) {
        error = new Error("page_not_found_error_message");
      } else if (response.status >= 500) {
        error = new Error("server_error_message");
      } else {
        error = new Error("general_error_message");
      }
      reject(error);
    }
  });
};

export const lookup = async (token) => {
  const url = new URL(LOOK_UP_URL);
  url.searchParams.append("device_info", true);
  url.searchParams.append("label_type", true);
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
  };
  return new Promise(async (resolve, reject) => {
    const response = await fetch(url.toString(), options);
    let error;
    if (response.ok) {
      const responseJson = await response.json();
      resolve(responseJson);
    } else {
      if (response.status === 401) {
        error = new Error("access_denied__error_message");
      } else if (response.status === 404) {
        error = new Error("page_not_found_error_message");
      } else if (response.status >= 500) {
        error = new Error("server_error_message");
      } else {
        error = new Error("general_error_message");
      }
      reject(error);
    }
  });
};

/**
 * @summary converted image metata, in json formate, to form data format
 *
 * @param {Object} params json object represents metadata for image
 * @returns form data generated by iamge metadata
 */
const getFormData = (params) => {
  const formData = new FormData();
  for (const key in params) {
    if (Object.hasOwnProperty.call(params, key)) {
      const element = params[key];
      formData.append(key, element);
    }
  }
  return formData;
};

const getBoudingBoxesUploadData = (boudingBoxes, dataPointId) => {
  let newBoundingBoxeData = {};
  for (let index = 0; index < boudingBoxes.length; index++) {
    const box = boudingBoxes[index];
    // new payload for 4-edge-polygon bounding box
    const {
      topLeft = [0, 0],
      topRight = [0, 0],
      bottomRight = [0, 0],
      bottomLeft = [0, 0],
    } = box;
    newBoundingBoxeData[`labels[${index}]bbox`] = JSON.stringify({
      bounding_box: [
        Math.floor(topLeft[0]),
        Math.floor(topLeft[1]),
        Math.floor(topRight[0]),
        Math.floor(topRight[1]),
        Math.floor(bottomRight[0]),
        Math.floor(bottomRight[1]),
        Math.floor(bottomLeft[0]),
        Math.floor(bottomLeft[1]),
      ],
    });
    newBoundingBoxeData[`labels[${index}]label_type`] = box.id;
    newBoundingBoxeData[`labels[${index}]rating`] = box.rating || 0;

    if (index === boudingBoxes.length - 1) {
      newBoundingBoxeData.datapoint = dataPointId;
    }
  }
  return newBoundingBoxeData;
};

export const createWorkOrder = async (params) => {
  const {
    region,
    lat,
    lon,
    datapoint_id,
    label_id,
    note,
    userName,
    imageUrl,
    label_type,
    w_o_type,
    open_datetime,
    assign_to,
    severity,
    w_o_id,
    map_image_url,
    inspected_on = "",
    inspected_by = "",
  } = params;

  const [open_date, timestamp] = new Date().toJSON().split("T");
  const [open_time] = timestamp.split(".");
  const data = [
    {
      region,
      lat,
      lon,
      datapoint_id,
      label_id,
      note,
      open_time: open_time,
      open_date: open_date,
      issued_by: userName,
      image_url: imageUrl,
      label_type,
      w_o_type,
      open_datetime,
      assign_to,
      severity,
      w_o_id,
      map_image_url,
      inspected_on,
      inspected_by,
    },
  ];

  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };
  return new Promise(async (resolve, reject) => {
    const response = await fetch(CREATE_WORK_ORDER_URL, options);
    let error;
    if (response.ok) {
      const responseJson = await response.json();
      resolve(responseJson);
    } else {
      const message = await response.text();
      const { status } = response;
      if (status === 401 || status === 403) {
        error = new Error(`code: ${status}, access denied, ${message}`);
      } else if (status === 404) {
        error = new Error(`code: ${status}, page not found, ${message}`);
      } else if (status >= 500) {
        error = new Error(`code: ${status}, internal server error, ${message}`);
      } else {
        error = new Error("general_error_message");
      }
      reject(error);
    }
  });
};

/**
 *
 * @returns
 */
export const createDefect = async (data, awsToken) => {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${awsToken}`,
    },
    body: JSON.stringify(data),
  };
  return new Promise(async (resolve, reject) => {
    const response = await fetch(CREATE_DEFECT_URL, options);
    let error;
    if (response.ok) {
      const responseJson = await response.json();
      resolve(responseJson);
    } else {
      const message = await response.text();
      const { status } = response;
      if (status === 401 || status === 403) {
        error = new Error(`code: ${status}, access denied, ${message}`);
      } else if (status === 404) {
        error = new Error(`code: ${status}, page not found, ${message}`);
      } else if (status >= 500) {
        error = new Error(`code: ${status}, internal server error, ${message}`);
      } else {
        error = new Error(`general_error_message: ${message}`);
      }
      reject(error);
    }
  });
};

/**
 *
 * @returns
 */
export const listDefectTypes = async (awsToken) => {
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${awsToken}`,
    },
  };
  return new Promise(async (resolve, reject) => {
    const response = await fetch(LIST_DEFECT_TYPE_URL, options);
    let error;
    if (response.ok) {
      const responseJson = await response.json();
      resolve(responseJson);
    } else {
      const message = await response.text();
      const { status } = response;
      if (status === 401 || status === 403) {
        error = new Error(`code: ${status}, access denied, ${message}`);
      } else if (status === 404) {
        error = new Error(`code: ${status}, page not found, ${message}`);
      } else if (status >= 500) {
        error = new Error(`code: ${status}, internal server error, ${message}`);
      } else {
        error = new Error(`general_error_message: ${message}`);
      }
      reject(error);
    }
  });
};
