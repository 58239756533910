import React, { SyntheticEvent, useEffect, useState } from "react";
import Snackbar, { SnackbarCloseReason } from "@material-ui/core/Snackbar";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";

const SEVERITY = ["error", "info", "warning", "success"] as const;

interface SnackbarProps {
  severity: typeof SEVERITY[number];
  open: boolean;
  handleClose?: () => void;
  duration?: number;
  message?: string;
  position:
    | "top-left"
    | "top-center"
    | "top-right"
    | "bottom-left"
    | "bottom-center"
    | "bottom-right";
}

const horizontalType = ["left", "center", "right"] as const;
interface AnchorProps {
  vertical: "top" | "bottom";
  horizontal: typeof horizontalType[number];
}

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function MuiSnackbar(props: SnackbarProps) {
  const {
    open: shouldOpen,
    handleClose: onClose,
    duration = 3000,
    severity = "success",
    message = "demo message",
    position = "",
  } = props;
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [anchorPosition, setAnchorPosition] = useState<AnchorProps>({
    vertical: "bottom",
    horizontal: "center",
  });

  const handleClose = (
    event: SyntheticEvent<Element, Event>,
    reason?: SnackbarCloseReason
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    typeof onClose === "function" && onClose();
  };

  useEffect(() => {
    setOpen(shouldOpen);
  }, [shouldOpen]);

  useEffect(() => {
    if (typeof position === "string" && position.includes("-")) {
      const [vertical, horizontal] = position.split("-");

      const anchor: AnchorProps = {
        vertical: "bottom",
        horizontal: "center",
      };
      if (vertical === "top" || vertical === "bottom") {
        anchor.vertical = vertical;
      }
      //   if (horizontalType.indexOf(horizontal) > -1) {
      //     anchor.horizontal = horizontal;
      //   }

      const index = ["left", "center", "right"].indexOf(horizontal);
      if (index > -1) {
        anchor.horizontal = horizontalType[index];
      }
      setAnchorPosition(anchor);
    }
  }, [position]);

  return (
    <div className={classes.root}>
      <Snackbar
        open={open}
        autoHideDuration={duration}
        onClose={handleClose}
        anchorOrigin={anchorPosition}
      >
        <Alert onClose={handleClose} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
}
