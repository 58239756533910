import { Switch, Route } from "react-router-dom";

import ManualPatrol from "../components/manualPatrol/ManualPatrol";
import ManualPatrolForm from "../pages/manualPatrol/ManualPatrolForm";

export function PageBody({ isDrawerOpened: openDrawer = true, ...props }) {
  return (
    <Switch>
      <Route exact path="/patrol" component={ManualPatrol} />
      <Route exact path="/patrol-form" component={ManualPatrolForm} />
    </Switch>
  );
}
