import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
// import Spinner from "../../../irisUI/spinner/Spinner";

import loadingImg from "../../assets/images/loading_gif.gif";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default function IrisLoader({ open: showLoader }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") return;
    setOpen(false);
  };

  useEffect(() => {
    setOpen(showLoader);
  }, [showLoader]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={handleClose}
      // closeAfterTransition // from demo code, but his break the UI, after close, the body cannot be scrolled, from html, the style is set to overflow: hidden
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div>
          <img src={loadingImg} alt="Loading..." />
        </div>
      </Fade>
    </Modal>
  );
}
