import { Auth } from "aws-amplify";
import { AWS_CHALLENGE_NAMES } from "aws/utils";
// import { getQueryItems } from "components/table/dataModal/workOrderDataModal";
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useHistory, useLocation } from "react-router-dom";
import { getIrisUser } from "./api/request";
import { datadogRum } from "@datadog/browser-rum";

/*================================================== additional setup for datadog START ================================================== */
const setupDatadog = (username) => {
  // https://docs.datadoghq.com/real_user_monitoring/browser/advanced_configuration/?tab=npm#identify-user-session
  datadogRum.setUser({
    name: username,
    // name: "lucien PC test manual start session replay",
  });

  const sessionReplaySampleRate =
    process.env.REACT_APP_DATADOG_REPLAY_SAMPLE_RATE || 0;
  // reference https://docs.datadoghq.com/real_user_monitoring/browser/
  datadogRum.init({
    // find values for this snippet from
    // UX Monitoring -> setup & configurations
    // find your app and find Edit Application
    applicationId: process.env.REACT_APP_DATADOG_APP_ID,
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
    site: "us5.datadoghq.com",
    service: "iriscity-dashboard",
    sessionSampleRate: 100,
    sessionReplaySampleRate: parseInt(sessionReplaySampleRate),
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input",
  });
};
/*==================================================  additional setup for datadog END  ================================================== */

/**
 * stand alone function to get token for the user
 * https://stackoverflow.com/questions/53375350/how-handle-refresh-token-service-in-aws-amplify-js
 * @returns
 */
export const getAwsToken = async () => {
  try {
    const response = await Auth.currentAuthenticatedUser();
    const token = response.signInUserSession.accessToken.jwtToken;
    return Promise.resolve(token);
  } catch (error) {
    throw new Error("Error in getting token, please logout and login again");
  }
};

/**
 * get city config out of user object
 * @param {TypeIrisUser} user
 *
 * @returns {TypeCityConfig}
 */
const getCityConfig = (user) => {
  /** first group info */
  const groupInfo = user.clients[0];

  const geoInfo = groupInfo.geo_info;
  /**@type {TypeCityConfig} */
  const cityConfig = {};
  cityConfig.name = groupInfo.service.iris_city.name;
  cityConfig.degree_preference = geoInfo.temp_unit;
  cityConfig.code = geoInfo.open_weather_id;
  cityConfig.province = geoInfo.province;
  cityConfig.timezone = geoInfo.timezone;
  cityConfig.latitude = geoInfo.lat;
  cityConfig.longitude = geoInfo.lon;
  cityConfig.deviceCount = groupInfo.device_count;
  cityConfig.id = groupInfo.id;

  return cityConfig;
};

const getClientConfig = (user) => {
  const client = {};
  client.id = user.clients[0].id;
  return client;
};

const DEFAULT_AUTH_CONTEXT = {
  /**@type {TypeIrisUser} Iris user object*/
  user: undefined,

  /** temp user used to reset password, if required by Cognito */
  tempUser: undefined,
  /**@type {TypePageConfig} */
  pageConfig: undefined,
  client: undefined,
  loading: false,
  loadingCachedUser: false,
  error: undefined,

  /**
   *
   * @param {string} userName cognito user name
   * @param {string} password coginto password
   * @returns {Promise<TypeIrisUser>}
   */
  login: (userName, password) => {
    // login fuction
  },
  logout: () => {
    // log out function
  },
  resetTempUser: () => {
    // reset user function
  },
};

const AuthContext = createContext(DEFAULT_AUTH_CONTEXT);

// Export the provider as we need to wrap the entire app with it
// https://dev.to/finiam/predictable-react-authentication-with-the-context-api-g10
export function AuthProvider(props) {
  const { children } = props;
  const [tempUser, setTempUser] = useState();
  /**@type {[user: TypeIrisUser]} */
  const [user, setUser] = useState();

  /**@type {[pageConfig: TypePageConfig]} */
  const [pageConfig, setPageConfig] = useState();
  const [client, setClient] = useState();

  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [loadingCachedUser, setLoadingCachedUser] = useState(true);
  // We are using `react-router` for this example,
  // but feel free to omit this or use the
  // router of your choice.
  const location = useLocation();
  const history = useHistory();

  // If we change page, reset the error state.
  useEffect(() => {
    if (error) setError(null);
  }, [error, location.pathname]);

  // extra configs, including routes (pages) and configs for each route (page) based on user info
  useEffect(() => {
    if (user) {
      /**@type {TypePageConfig} */
      const pageConfig = {};

      // set up city config
      const cityConfig = getCityConfig(user);
      pageConfig.city = cityConfig;

      setClient(getClientConfig(user));

      const { w_o_profile, patrol_report, widgets, manual_app_profile } =
        user.clients[0].service.iris_city;

      // set up dashboard configs (widgets)

      if (widgets) {
        const dashboardConfig = JSON.parse(JSON.stringify(widgets));
        pageConfig.dashboard = dashboardConfig;
      }

      // set up work order config
      if (w_o_profile) {
        /**@type {TypeWorkorderConfig} */
        const workorderConfig = JSON.parse(JSON.stringify(w_o_profile));
        pageConfig.workorder = workorderConfig;
      }

      if (manual_app_profile) {
        const { device_list, additional_setting, shift, coverage_layer } =
          manual_app_profile;
        pageConfig.patrolConfig = {};
        pageConfig.patrolConfig.deviceList = device_list;
        const { mdss, routes, show_workorder } = additional_setting;
        pageConfig.patrolConfig.additionalSetting = {
          mdss,
          routes,
          showWorkorder: show_workorder,
        };

        // config shifts make into array of object for rendering purpose
        const shifts = shift.map((shiftStr) => ({
          id: shiftStr,
          name: shiftStr,
        }));
        pageConfig.patrolConfig.shift = shifts;

        // extra coverage layer
        if (coverage_layer) {
          pageConfig.patrolConfig.coverageLayer = coverage_layer;
        }
      }

      setPageConfig(pageConfig);

      // set up route objects for current user
      const irisCityServices = user.clients[0].service.iris_city;
      setupDatadog(user.username);
      const serviceKeys = Object.keys(irisCityServices).filter((key) => {
        const value = irisCityServices[key];
        if (value) {
          return true;
        }
        return false;
      });
    }
  }, [user]);

  const login = async (userName, password) => {
    /**@type {TypeCognitoUser} */
    let cognitoUser;

    /** @type {TypeIrisUser} */
    let irisUser, tempUser, error;
    try {
      setLoading(true);
      cognitoUser = await Auth.signIn(userName, password);

      if (cognitoUser) {
        // if need to reset password, indicate by Cognito
        const { challengeName } = cognitoUser;
        if (challengeName === AWS_CHALLENGE_NAMES.NEW_PASSWORD_REQUIRED) {
          // in this case, use is required to reset his/her password
          // keep a reference for this user
          tempUser = cognitoUser;
        } else {
          const token = cognitoUser.signInUserSession.accessToken.jwtToken;
          irisUser = await getIrisUser(token);

          if (irisUser.clients.length === 0) {
            throw new Error(
              `${irisUser.username} has not been assigned to any groups`
            );
          }
          if (!irisUser.clients[0].service.iris_city.manual_app_profile) {
            throw new Error("Your manual_app_profile is not set up yet");
          }
        }
      }
    } catch (e) {
      error = e;
    } finally {
      setLoading(false);
      if (error) {
        setError(error);
      } else if (tempUser) {
        // reset password modal would show on Login page
        setTempUser(tempUser);
      } else if (irisUser) {
        setUser(irisUser);
      }
    }
  };

  /**
   * Sign out from the app.
   * @description
     1. sign out from Cognito first => local credential would be cleared autmoatically
     2. reset user => clear local state
     3. push user to login page
   */
  const logout = () => {
    Auth.signOut();
    setUser(undefined);
    history.replace("/login");
    datadogRum.stopSession();
  };

  // Make the provider update only when it should.
  // We only want to force re-renders if the user,
  // loading or error states change.
  //
  // Whenever the `value` passed into a provider changes,
  // the whole tree under the provider re-renders, and
  // that can be very costly! Even in this case, where
  // you only get re-renders when logging in and out
  // we want to keep things very performant.
  const memoedValue = useMemo(
    () => ({
      tempUser,
      user,
      pageConfig,
      client,
      loading,
      loadingCachedUser,
      error,
      login,
      logout,
      resetTempUser: () => {
        setTempUser(null);
      },
    }),
    [tempUser, user, pageConfig, loading, loadingCachedUser, error]
  );

  // We only want to render the underlying app after we
  // assert for the presence of a current user.
  return (
    <AuthContext.Provider value={memoedValue}>{children}</AuthContext.Provider>
  );
}

// Let's only export the `useAuth` hook instead of the context.
// We only want to use the hook directly and never the context component.
export default function useAuth() {
  return useContext(AuthContext);
}
