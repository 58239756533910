export class CommonTranslate {
  static ROOT_KEY = "COMMON";

  static Good_Morning = new CommonTranslate("Good_Morning");
  static MORNING = new CommonTranslate("MORNING");
  static AFTERNOON = new CommonTranslate("AFTERNOON");
  static EVENING = new CommonTranslate("EVENING");
  static NIGHT = new CommonTranslate("NIGHT");
  static SUMMER = new CommonTranslate("SUMMER");
  static WINTER = new CommonTranslate("WINTER");
  static ON = new CommonTranslate("ON");

  constructor(name) {
    this.name = name;
  }

  toString() {
    return CommonTranslate.ROOT_KEY + `.${this.name}`;
  }
}
